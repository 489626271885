import ForwardIcon from '@mui/icons-material/Forward';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { signIn } from '../utils/authenticate';
import { captureError } from '../utils/capture-error';

export function SignInBanner(props: { message?: string }) {
  const handleSignIn = async () => {
    try {
      await signIn({ window });
    } catch (error) {
      captureError({ data: { error } });
    }
  };

  return (
    <Paper
      sx={{
        padding: 2,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#3D434944',
      }}
    >
      <LockOpenIcon fontSize="large" color="warning" />

      <Box sx={{ flex: 1, fontSize: 20, marginLeft: 1 }}>
        {props.message || 'Please sign in'}
      </Box>

      <Button
        size="large"
        variant="contained"
        endIcon={<ForwardIcon />}
        onClick={handleSignIn}
      >
        Sign in
      </Button>
    </Paper>
  );
}
