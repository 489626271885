import React from 'react';
import { AuthContext } from '../contexts/auth-context';
import { AWS_GROUP } from '../types';

export function usePermissions() {
  // Context
  const { state } = React.useContext(AuthContext);

  // Authenticated User Permissions to be returned from hook
  const permissions: {
    groups: string[];
    hasGroup: (group: string) => boolean;
    checkPermissions: (list: string[]) => boolean;
  } = {
    groups: [],
    hasGroup: (group) => false,
    checkPermissions: (list) => false,
  };

  if (state.session) {
    const payload = state.session.getIdToken().decodePayload();
    if (payload['cognito:groups']) {
      const cognitoGroups = payload['cognito:groups'] as string[];

      permissions.groups = cognitoGroups;

      permissions.hasGroup = (group) => {
        return cognitoGroups.includes(group);
      };

      permissions.checkPermissions = (list) => {
        if (cognitoGroups.includes(AWS_GROUP.GlobalAdmin)) {
          return true;
        } else {
          const values: boolean[] = list.map((i) => {
            return cognitoGroups.includes(i);
          });
          return values.includes(true);
        }
      };
    }
  }

  return permissions;
}
