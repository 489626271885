import ForwardIcon from '@mui/icons-material/Forward';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { useQueryClient } from '@tanstack/react-query';
import { Auth } from 'aws-amplify';
import React from 'react';
import { AuthContext } from '../contexts/auth-context';
import { signIn } from '../utils/authenticate';
import { captureError } from '../utils/capture-error';

export function TokenError() {
  // Context
  const { state } = React.useContext(AuthContext);

  const queryClient = useQueryClient();
  const handleSignOut = async () => {
    // Remove any cached data
    queryClient.removeQueries();
    // Sign out current user from Cognito
    await Auth.signOut();
    // After being signed out from Cognito
    // Hub.listen > 'auth' > 'signOut' will remove user  data from Context
  };

  const handleSignIn = async () => {
    try {
      await signIn({ window });
    } catch (error) {
      captureError({ data: { error } });
    }
  };

  if (state.tokenError) {
    return (
      <Dialog open>
        <DialogTitle>Please Sign In again</DialogTitle>
        <DialogActions>
          <Button color="secondary" onClick={handleSignOut}>
            Cancel
          </Button>
          <Button
            variant="contained"
            endIcon={<ForwardIcon />}
            onClick={handleSignIn}
          >
            Sign in
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else {
    return null;
  }
}
