import axios from 'axios';
import {
  ResponseVimeo,
  ResponseYouTube,
  VideoData,
  VideoSavedData,
} from '../types';

export const videoSavedStatus = (params: {
  video: VideoData;
  saves: VideoSavedData[];
}) => {
  // Results to be returned
  const results: {
    saved: boolean;
    save: VideoSavedData | undefined;
  } = {
    saved: false,
    save: undefined,
  };

  // Find this video in the users saved videos
  const save = params.saves.find((i) => i.VideoId === params.video.VideoId);

  // If the video has been found
  if (save) {
    results.saved = true;
    results.save = save;
  }

  return results;
};

export const getVideoDetailsYouTube = async (embedURL: string) => {
  const videoId = embedURL.split('/').pop();
  if (videoId) {
    const url = `https://www.youtube.com/oembed?url=https://www.youtube.com/watch?v=${videoId}&format=json`;
    const response = await axios.get<ResponseYouTube>(url);
    return response.data;
  }
};

export const getVideoDetailsVimeo = async (embedURL: string) => {
  const videoId = embedURL.split('/').pop()?.split('?').shift();
  if (videoId) {
    const url = `https://vimeo.com/api/v2/video/${videoId}.json`;
    const response = await axios.get<ResponseVimeo[]>(url);
    if (response.data.length) {
      return response.data[0];
    }
  }
};
