import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import StarIcon from '@mui/icons-material/Star';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import { API } from 'aws-amplify';
import React from 'react';
import { Link } from 'react-router-dom';
import { AnimateIn } from '../../components/animate-in';
import { CourseCategoryChip } from '../../components/course-category-chip';
import { CourseLevelIcon } from '../../components/course-level-icon';
import { CourseTile } from '../../components/course-tile';
import { RouteContainer } from '../../components/route-container';
import { RouteHeader } from '../../components/route-header';
import { AuthContext } from '../../contexts/auth-context';
import {
  BookmarkData,
  CompletedSecData,
  CourseCategoryData,
  CourseData,
} from '../../types';
import { captureError } from '../../utils/capture-error';

function DisplayCourse(props: {
  course: CourseData;
  categories: CourseCategoryData[];
  direction: 'left' | 'right';
}) {
  // Props
  const { course, direction, categories } = props;

  return (
    <AnimateIn to={direction}>
      <Paper
        component={Link}
        to={`/courses/${course.CourseSlug}`}
        sx={{
          flex: 1,
          display: 'block',
          textDecorationLine: 'none',
          minHeight: 360,
          border: '4px solid transparent',
          borderStyle: 'inset',
          borderRadius: 2,
          transition: 'all 0.2s',
          ':hover': {
            transform: 'scale(1.01)',
            border: '4px solid #ffffff77',
          },
        }}
      >
        <Grid container spacing={2} sx={{ padding: 2 }}>
          <Grid item xs={12} md={course.Image ? 8 : 12}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{ fontFamily: 'Bebas Neue', fontSize: 44 }}>
                {course.CourseNo} - {course.Title}
              </Box>
            </Box>

            {course.CategoryId ? (
              <Box sx={{ padding: 1 }}>
                <CourseCategoryChip course={course} categories={categories} />
              </Box>
            ) : null}

            <Box sx={{ fontSize: 18, paddingTop: 2, paddingBottom: 2 }}>
              {course.Description}
            </Box>

            {course.Required ? (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: 2,
                }}
              >
                <StarIcon sx={{ color: 'yellow' }} />
                <Box sx={{ marginLeft: 1 }}>This is a required course</Box>
              </Box>
            ) : null}

            {course.Level !== null ? (
              <Box sx={{ marginBottom: 2 }}>
                <CourseLevelIcon course={course} withDescription />
              </Box>
            ) : null}

            {course.Certificate ? (
              <Box
                sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}
              >
                <WorkspacePremiumIcon color="info" />
                <Box sx={{ marginLeft: 1 }}>
                  This course comes with a Certificate
                </Box>
              </Box>
            ) : null}
          </Grid>

          {course.Image ? (
            <Grid item xs={12} md={4}>
              <Box
                sx={{
                  height: 264,
                  width: '100%',
                  color: '#fff',
                  borderRadius: 2,
                  backgroundColor: '#3D4349',
                  backgroundImage: `url(${course.Image})`,
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                }}
              />
            </Grid>
          ) : null}
        </Grid>
      </Paper>
    </AnimateIn>
  );
}

export function Featured() {
  // Context
  const { state } = React.useContext(AuthContext);
  // State
  const [loading, setLoading] = React.useState(true);
  const [hasError, setHasError] = React.useState(false);
  const [categories, setCategories] = React.useState<CourseCategoryData[]>([]);
  const [bookmarks, setBookmarks] = React.useState<BookmarkData[]>([]);
  const [completedSecs, setCompletedSecs] = React.useState<CompletedSecData[]>(
    []
  );
  const [added, setAdded] = React.useState<CourseData[]>([]);
  const [updated, setUpdated] = React.useState<CourseData[]>([]);
  const [featured, setFeatured] = React.useState<CourseData[]>([]);
  const [slide, setSlide] = React.useState(0);
  const [direction, setDirection] = React.useState<'left' | 'right'>('left');

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        // Courses
        const response: {
          data: CourseData[];
        } = await API.post('UniversityAPI', '/courses', {
          body: { PublishedOnly: 1 },
        });
        // Featued Courses
        const newAdded: CourseData[] = response.data.filter(
          (i) => i.FeatAdded === 1
        );
        const newUpdated: CourseData[] = response.data.filter(
          (i) => i.FeatUpdated === 1
        );
        const newFeatured: CourseData[] = response.data.filter(
          (i) => i.FeatCarousel === 1
        );
        setAdded(newAdded);
        setUpdated(newUpdated);
        setFeatured(newFeatured);

        // Course Categories
        const responseCategories: {
          data: CourseCategoryData[];
        } = await API.post('UniversityAPI', '/courses/categories', {});
        setCategories(responseCategories.data);

        if (state.user) {
          // User Bookmarks
          const responseBookmarks: {
            data: BookmarkData[];
          } = await API.post('UniversityAPI', '/courses/bookmarks', {});
          setBookmarks(responseBookmarks.data);

          // User Sections Completed
          const responseCompleted: {
            data: CompletedSecData[];
          } = await API.post('UniversityAPI', '/sections/completed', {});
          setCompletedSecs(responseCompleted.data);
        }
      } catch (error) {
        captureError({ data: { error } });
        setHasError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [state.user]);

  const handlePrevSlide = () => {
    setDirection('right');
    setSlide((currentState) => {
      if (currentState - 1 < 0) {
        return featured.length - 1;
      } else {
        return currentState - 1;
      }
    });
  };

  const handleNextSlide = () => {
    setDirection('left');
    setSlide((currentState) => {
      if (currentState + 1 === featured.length) {
        return 0;
      } else {
        return currentState + 1;
      }
    });
  };

  const course = featured[slide];

  return (
    <RouteContainer
      routeTitle="Featured"
      notransform="true"
      loading={loading}
      hasError={hasError}
      headerContent={<RouteHeader mainText="Featured" />}
    >
      <Container sx={{ paddingTop: 4, paddingBottom: 6 }}>
        <Grid container spacing={4}>
          {course ? (
            <Grid item xs={12}>
              <DisplayCourse
                key={slide}
                course={course}
                categories={categories}
                direction={direction}
              />

              <Box
                sx={{ display: 'flex', alignItems: 'center', paddingTop: 2 }}
              >
                <IconButton
                  sx={{ border: '1px solid #ffffff22' }}
                  onClick={handlePrevSlide}
                >
                  <NavigateBeforeIcon />
                </IconButton>

                <Box
                  sx={{
                    flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {featured.map((item, index) => {
                    return (
                      <Box key={item.CourseId} sx={{ padding: 2 }}>
                        <Box
                          sx={{
                            height: 8,
                            width: 8,
                            borderRadius: 8,
                            backgroundColor: '#fff',
                            cursor: 'pointer',
                            opacity: index === slide ? 1 : 0.3,
                          }}
                          onClick={() => setSlide(index)}
                        />
                      </Box>
                    );
                  })}
                </Box>

                <IconButton
                  sx={{ border: '1px solid #ffffff22' }}
                  onClick={handleNextSlide}
                >
                  <NavigateNextIcon />
                </IconButton>
              </Box>
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <Box
              sx={{
                fontFamily: 'Bebas Neue',
                fontSize: 44,
                lineHeight: 1,
              }}
            >
              Recently Added
            </Box>
          </Grid>

          {added.map((item) => {
            return (
              <Grid key={item.CourseId} item xs={12} sm={6} md={4}>
                <CourseTile
                  item={item}
                  categories={categories}
                  user={state.user}
                  bookmarks={bookmarks}
                />
              </Grid>
            );
          })}

          <Grid item xs={12}>
            <Box
              sx={{
                fontFamily: 'Bebas Neue',
                fontSize: 44,
                lineHeight: 1,
              }}
            >
              Recently Updated
            </Box>
          </Grid>

          {updated.map((item) => {
            return (
              <Grid key={item.CourseId} item xs={12} sm={6} md={4}>
                <CourseTile
                  item={item}
                  categories={categories}
                  user={state.user}
                  bookmarks={bookmarks}
                  completedSecs={completedSecs}
                />
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </RouteContainer>
  );
}
