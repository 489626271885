import LinearProgress from '@mui/material/LinearProgress';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Dashboard } from '../components/dashboard';
import { RequireAuth } from '../components/require-auth';
import { AuthContext } from '../contexts/auth-context';
import { usePermissions } from '../hooks/use-permissions';
import { AWS_GROUP } from '../types';
import { CarrierTools } from './carrier-tools';
import { Course } from './course';
import { Courses } from './courses';
import { Featured } from './featured';
import { Glossary } from './glossary';
import { Grid } from './grid';
import { Home } from './home';
import { ProductGuide } from './product-guide';
import { Resources } from './resources';
import { SignIn } from './sign-in';
import { Video } from './video';
import { Videos } from './videos';

const AppRoutes = {
  Account: React.lazy(() => import('./account')),
  Admin: React.lazy(() => import('./admin')),
};

export function Root() {
  // Context
  const { state } = React.useContext(AuthContext);
  // Hooks
  const { checkPermissions } = usePermissions();

  // Query - Check for Maintenance
  const pathMaint = process.env.REACT_APP_MAINT_URL;
  const queryMaint = useQuery({
    enabled: pathMaint !== undefined,
    queryKey: [pathMaint],
    queryFn: async () => {
      if (pathMaint) {
        const body = { data: { origin: window.origin } };
        const response = await fetch(pathMaint, {
          body: JSON.stringify(body),
          headers: { 'Content-Type': 'application/json' },
          method: 'POST',
        });
        const data: {
          data: {
            maintenanceActive: boolean;
            maintenanceBegin: string;
            maintenanceEnd: string;
          };
        } = await response.json();

        return data.data;
      }

      return null;
    },
  });

  const isAuthenticated = state.user !== undefined;
  const isAdmin = checkPermissions([AWS_GROUP.Marketing]);

  const navigateHome = <Navigate to="/home" />;

  return (
    <Routes>
      <Route path="sign-in" element={<SignIn />} />

      <Route
        path="/"
        element={
          <RequireAuth isAdmin={isAdmin} maintData={queryMaint.data}>
            <Dashboard />
          </RequireAuth>
        }
      >
        <Route path="home" element={<Home />} />
        <Route path="featured" element={<Featured />} />
        <Route path="courses/:courseId" element={<Course />} />
        <Route path="courses" element={<Courses />} />
        <Route path="videos/:videoId" element={<Video />} />
        <Route path="videos" element={<Videos />} />
        <Route path="resources" element={<Resources />} />
        <Route path="resources/grids/:gridId" element={<Grid />} />
        <Route path="resources/product-guide" element={<ProductGuide />} />
        <Route path="resources/glossary" element={<Glossary />} />
        <Route path="resources/carrier-tools" element={<CarrierTools />} />
        <Route
          path="account"
          element={
            isAuthenticated ? (
              <React.Suspense fallback={<LinearProgress />}>
                <AppRoutes.Account />
              </React.Suspense>
            ) : (
              navigateHome
            )
          }
        />
        <Route
          path="admin/*"
          element={
            isAdmin ? (
              <React.Suspense fallback={<LinearProgress />}>
                <AppRoutes.Admin />
              </React.Suspense>
            ) : isAuthenticated ? (
              <Navigate replace to="/account" />
            ) : (
              navigateHome
            )
          }
        />

        <Route path="/" element={navigateHome} />
        <Route path="*" element={navigateHome} />
      </Route>
    </Routes>
  );
}
