import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import orderBy from 'lodash/orderBy';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { RouteContainer } from '../../components/route-container';
import { RouteHeader } from '../../components/route-header';
import { SignInBanner } from '../../components/sign-in-banner';
import { VideoTile } from '../../components/video-tile';
import { AuthContext } from '../../contexts/auth-context';
import {
  VideoCarrierData,
  VideoCategoryData,
  VideoData,
  VideoProductData,
  VideoSavedData,
} from '../../types';
import { captureError } from '../../utils/capture-error';
import { searchBy } from '../../utils/search-by';
import { ConferenceCalls } from './conference-calls';

export function Videos() {
  // Context
  const { state } = React.useContext(AuthContext);
  // State
  const [category, setCategory] = React.useState('All');
  const [product, setProduct] = React.useState('All');
  const [carrier, setCarrier] = React.useState('All');
  const [searchString, setSearchString] = React.useState('');
  // Hooks
  const [search, setSearch] = useSearchParams();

  const isAuthenticated = state.user !== undefined;

  // Query - Videos
  const pathVideos = '/video-vault';
  const queryVideos = useQuery({
    enabled: isAuthenticated,
    queryKey: [pathVideos],
    queryFn: async () => {
      const response: {
        data: VideoData[];
      } = await API.post('UniversityAPI', pathVideos, {});

      if (response.data) {
        return response.data;
      } else {
        return [];
      }
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Query - Video Filters
  const pathFilters = '/video-vault/filters';
  const queryFilters = useQuery({
    enabled: isAuthenticated,
    queryKey: [pathFilters],
    queryFn: async () => {
      const response: {
        data: {
          Categories: VideoCategoryData[];
          Products: VideoProductData[];
          Carriers: VideoCarrierData[];
        };
      } = await API.post('UniversityAPI', pathFilters, {});

      if (response.data) {
        return response.data;
      } else {
        return { Categories: [], Products: [], Carriers: [] };
      }
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Query - Saved Videos
  const pathSaved = '/video-vault/saved-list';
  const querySaved = useQuery({
    enabled: isAuthenticated,
    queryKey: [pathSaved],
    queryFn: async () => {
      const response: {
        data: VideoSavedData[];
      } = await API.post('UniversityAPI', pathSaved, {});

      if (response.data) {
        return response.data;
      } else {
        return [];
      }
    },
    onError: (error) => captureError({ data: { error } }),
  });

  React.useEffect(() => {
    // Category filter for courses
    if (search.get('category') !== null) {
      setCategory(search.get('category') || 'All');
    } else {
      setCategory('All');
    }
    // Product filter for courses
    if (search.get('product') !== null) {
      setProduct(search.get('product') || 'All');
    } else {
      setProduct('All');
    }
    // Carrier filter for courses
    if (search.get('carrier') !== null) {
      setCarrier(search.get('carrier') || 'All');
    } else {
      setCarrier('All');
    }
  }, [search]);

  const handleNavigate = (params: {
    category: string;
    product: string;
    carrier: string;
  }) => {
    const newParams: any = {};
    if (params.category !== 'All') {
      newParams['category'] = params.category;
    }
    if (params.product !== 'All') {
      newParams['product'] = params.product;
    }
    if (params.carrier !== 'All') {
      newParams['carrier'] = params.carrier;
    }
    setSearch(newParams);
  };

  // Categories for Select
  const dataCategories = queryFilters.data
    ? queryFilters.data.Categories.map((i) => ({
        ...i,
        CategoryId: Number(i.CategoryId),
      }))
    : [];
  const allCategories = orderBy(dataCategories.map((i) => i.Title));
  const selectCategories = ['All', ...allCategories];
  // Products for Select
  const dataProducts = queryFilters.data
    ? queryFilters.data.Products.map((i) => ({
        ...i,
        ProductId: Number(i.ProductId),
      }))
    : [];
  const allProducts = orderBy(dataProducts.map((i) => i.Title));
  const selectProducts = ['All', ...allProducts];
  // Carriers for Select
  const dataCarriers = queryFilters.data
    ? queryFilters.data.Carriers.map((i) => ({
        ...i,
        CarrierId: Number(i.CarrierId),
      }))
    : [];
  const allCarriers = orderBy(dataCarriers.map((i) => i.Title));
  const selectCarriers = ['All', ...allCarriers];

  // Filter by search
  let searchItems = queryVideos.data || [];
  if (searchString !== '') {
    searchItems = searchBy(queryVideos.data || [], searchString);
  }
  // Filter by categories
  let categoryItems = searchItems;
  if (category !== 'All') {
    const selectedCategory = dataCategories.find((i) => i.Title === category);
    categoryItems = searchItems.filter(
      (i) => i.CategoryId === selectedCategory?.CategoryId
    );
  }
  // Filter by products
  let productItems = categoryItems;
  if (product !== 'All') {
    const selectedProduct = dataProducts.find((i) => i.Title === product);
    productItems = categoryItems.filter(
      (i) => i.ProductId === selectedProduct?.ProductId
    );
  }
  // Filter by carriers
  let carrierItems = productItems;
  if (carrier !== 'All') {
    const selectedCarrier = dataCarriers.find((i) => i.Title === carrier);
    carrierItems = productItems.filter(
      (i) => i.CarrierId === selectedCarrier?.CarrierId
    );
  }
  // Order the courses
  const orderedItems = orderBy(carrierItems, 'UploadDate', 'desc');

  const isLoading =
    (queryVideos.isLoading && queryVideos.fetchStatus !== 'idle') ||
    (queryFilters.isLoading && queryFilters.fetchStatus !== 'idle') ||
    (querySaved.isLoading && querySaved.fetchStatus !== 'idle');

  const isError =
    queryVideos.isError || queryFilters.isError || querySaved.isError;

  return (
    <RouteContainer
      routeTitle="Video Vault"
      loading={isLoading}
      hasError={isError}
      headerContent={<RouteHeader mainText="Video Vault" />}
    >
      {isAuthenticated ? (
        <Container sx={{ paddingTop: 4, paddingBottom: 4 }}>
          <ConferenceCalls />

          <Grid container spacing={4} alignItems="stretch">
            <Grid item xs={12}>
              <TextField
                id="videos-search"
                variant="outlined"
                placeholder="Search videos"
                type="search"
                color="secondary"
                fullWidth
                value={searchString}
                onChange={(event) => setSearchString(event.target.value)}
                sx={{ backgroundColor: '#ffffff11' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth color="secondary">
                <InputLabel id="category-select-label">Category</InputLabel>
                <Select
                  labelId="category-select-label"
                  id="category-select"
                  value={category}
                  label="Category"
                  onChange={(event) => {
                    const value = event.target.value as string;
                    handleNavigate({ category: value, product, carrier });
                  }}
                >
                  {selectCategories.map((item) => {
                    let count = searchItems.length;
                    if (item !== 'All') {
                      const selectedCategory = dataCategories.find(
                        (i) => i.Title === item
                      );
                      count = searchItems.filter(
                        (i) => i.CategoryId === selectedCategory?.CategoryId
                      ).length;
                    }
                    return (
                      <MenuItem key={item} value={item}>
                        {item} ({count})
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth color="secondary">
                <InputLabel id="product-select-label">Product</InputLabel>
                <Select
                  labelId="product-select-label"
                  id="product-select"
                  value={product}
                  label="Product"
                  onChange={(event) => {
                    const value = event.target.value as string;
                    handleNavigate({ category, product: value, carrier });
                  }}
                >
                  {selectProducts.map((item) => {
                    let count = searchItems.length;
                    if (item !== 'All') {
                      const selectedProduct = dataProducts.find(
                        (i) => i.Title === item
                      );
                      count = searchItems.filter(
                        (i) => i.ProductId === selectedProduct?.ProductId
                      ).length;
                    }
                    return (
                      <MenuItem key={item} value={item}>
                        {item} ({count})
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth color="secondary">
                <InputLabel id="carrier-select-label">Carrier</InputLabel>
                <Select
                  labelId="carrier-select-label"
                  id="carrier-select"
                  value={carrier}
                  label="Carrier"
                  onChange={(event) => {
                    const value = event.target.value as string;
                    handleNavigate({ category, product, carrier: value });
                  }}
                >
                  {selectCarriers.map((item) => {
                    let count = searchItems.length;
                    if (item !== 'All') {
                      const selectedCarrier = dataCarriers.find(
                        (i) => i.Title === item
                      );
                      count = searchItems.filter(
                        (i) => i.CarrierId === selectedCarrier?.CarrierId
                      ).length;
                    }
                    return (
                      <MenuItem key={item} value={item}>
                        {item} ({count})
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            {orderedItems.map((item) => {
              return (
                <Grid key={item.VideoId} item xs={12} sm={6} md={4}>
                  <VideoTile
                    video={item}
                    categories={dataCategories}
                    flexDirection="column"
                    saves={querySaved.data || []}
                    user={state.user}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Container>
      ) : (
        <Container maxWidth="lg" sx={{ pt: 4, pb: 4 }}>
          <Grid container spacing={4} alignItems="stretch">
            <Grid item xs={12}>
              <SignInBanner message="Sign in to view Videos" />
            </Grid>

            {Array.from({ length: 9 }).map((_, index) => {
              return (
                <Grid key={index} item xs={12} sm={6} md={4}>
                  <Paper
                    elevation={0}
                    sx={{
                      height: 280,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <PlayCircleOutlineIcon
                      sx={{ fontSize: 84, opacity: 0.6 }}
                    />
                  </Paper>
                </Grid>
              );
            })}

            <Grid item xs={12}>
              <SignInBanner message="Sign in to view Videos" />
            </Grid>
          </Grid>
        </Container>
      )}
    </RouteContainer>
  );
}
