import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import Bowser from 'bowser';
import { serializeError } from 'serialize-error';

export async function captureError(params: {
  data: any;
  removeData?: boolean;
}) {
  const body: any = {};

  if (params.data) {
    body.data = params.data;
    if (params.data.error) {
      // create a copy of params.data.error before its serialized
      const newError = params.data.error;
      // serializeError will mutate the error passed in params.data.error by calling toJSON on the axios error
      body.data.error = serializeError(params.data.error);
      // Remove error config headers
      if (body.data.error?.config?.headers) {
        delete body.data.error.config.headers;
      }
      // Remove error response config headers
      if (body.data.error?.response?.config?.headers) {
        delete body.data.error.response.config.headers;
      }
      // Remove error response headers
      if (body.data.error?.response?.headers) {
        delete body.data.error.response.headers;
      }

      if (params.removeData) {
        // Remove config data
        if (body.data.error?.config?.data) {
          delete body.data.error.config.data;
        }
        // Remove response config data
        if (body.data.error?.response?.config?.data) {
          delete body.data.error.response.config.data;
        }
      }

      if (newError?.response?.data?.errors) {
        // If the API has returned an errors object like in the case of a 400 response
        // serialize and add it to the body data
        body.data.errors = serializeError(newError.response.data.errors);
      }
    }
    body.data.window = {};
    if (window.navigator && window.navigator.userAgent) {
      body.data.window.userAgent = window.navigator.userAgent;
      // Browser Info
      body.data.client = Bowser.parse(window.navigator.userAgent);
    }
    if (window.location && window.location.href) {
      body.data.window.href = window.location.href;
    }
    if (process.env.REACT_APP_AWS_BRANCH) {
      body.data.AWS_BRANCH = process.env.REACT_APP_AWS_BRANCH;
    }
    // Current User
    try {
      const user: CognitoUser = await Auth.currentAuthenticatedUser();
      if (user) {
        body.data.username = user.getUsername();
      }
    } catch (error) {
      // No current user
    }
  }

  if (
    process.env.NODE_ENV === 'production' &&
    process.env.REACT_APP_ALERTS_URL
  ) {
    fetch(process.env.REACT_APP_ALERTS_URL, {
      body: JSON.stringify(body),
      headers: { 'Content-Type': 'application/json' },
      method: 'POST',
    });
  } else {
    console.error({ captureError: params });
  }
}
