import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { SxProps } from '@mui/system';
import React from 'react';
import { Link } from 'react-router-dom';

const styles: { [key: string]: SxProps } = {
  paper: {
    display: 'block',
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    textDecorationLine: 'none',
    transition: 'all 0.2s',
    ':hover': {
      transform: 'scale(1.05)',
    },
  },
  content: {
    height: '100%',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '4px solid transparent',
    borderStyle: 'inset',
    transition: 'all 0.2s',
    ':hover': {
      border: '4px solid #ffffff77',
    },
  },
  text: {
    fontFamily: 'Bebas Neue',
    textAlign: 'center',
  },
};

export function ContentTile(props: {
  text: string;
  to?: string;
  href?: string;
  height?: React.CSSProperties['height'];
  backgroundColor?: React.CSSProperties['backgroundColor'];
  fontSize?: React.CSSProperties['fontSize'];
  imageUrl?: string;
}) {
  // Custom Styles
  const height = props.height || 264;
  const backgroundColor =
    props.backgroundColor || props.imageUrl ? '#00000099' : '#3D4349';
  const backgroundImage = props.imageUrl ? `url(${props.imageUrl})` : 'none';
  const fontSize = props.fontSize || 40;

  if (props.to) {
    return (
      <Paper
        component={Link}
        to={props.to}
        sx={styles.paper}
        style={{ height, backgroundImage }}
      >
        <Box sx={styles.content} style={{ backgroundColor }}>
          <Box sx={styles.text} style={{ fontSize }}>
            {props.text}
          </Box>
        </Box>
      </Paper>
    );
  } else if (props.href) {
    return (
      <Paper
        component="a"
        href={props.href}
        target="_blank"
        rel="noopener noreferrer"
        sx={styles.paper}
        style={{ height, backgroundImage }}
      >
        <Box sx={styles.content} style={{ backgroundColor }}>
          <Box sx={styles.text} style={{ fontSize }}>
            {props.text}
          </Box>
        </Box>
      </Paper>
    );
  } else {
    return (
      <Paper sx={styles.paper} style={{ height, backgroundImage }}>
        <Box sx={styles.content} style={{ backgroundColor }}>
          <Box sx={styles.text} style={{ fontSize }}>
            {props.text}
          </Box>
        </Box>
      </Paper>
    );
  }
}
