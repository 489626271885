import { createTheme } from '@mui/material/styles';

export const materialTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#CE0E2D',
    },
    secondary: {
      main: '#fff',
    },
    background: {
      paper: '#3D4349',
      default: '#1E2124',
    },
  },
  components: {
    MuiTableCell: {
      styleOverrides: {
        sizeSmall: {
          padding: 6,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          border: '1px solid #9b9b9b55',
          borderRadius: 14,
        },
        sizeMedium: {
          borderRadius: 10,
        },
        sizeSmall: {
          borderRadius: 8,
        },
      },
    },
  },
});
