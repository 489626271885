import CloseIcon from '@mui/icons-material/Close';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Backdrop from '@mui/material/Backdrop';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import { VideoFrame } from '../../components/video-frame';

export function HomeVideo() {
  // State
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <div>
      <IconButton
        size="large"
        sx={{ backgroundColor: '#ffffff33', height: 80, width: 80 }}
        onClick={handleToggle}
      >
        <PlayArrowIcon sx={{ height: 64, width: 64 }} />
      </IconButton>

      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <IconButton
          size="large"
          sx={{
            position: 'absolute',
            top: 72,
            right: 24,
            backgroundColor: '#ffffff22',
          }}
          onClick={handleClose}
        >
          <CloseIcon fontSize="large" />
        </IconButton>

        {open ? (
          <div style={{ width: '90vw', maxWidth: 1080 }}>
            <VideoFrame
              src="https://player.vimeo.com/video/761119930?h=20d7857814"
              title="NAAU Home Video"
            />
          </div>
        ) : null}
      </Backdrop>
    </div>
  );
}
