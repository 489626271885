import BeenhereIcon from '@mui/icons-material/Beenhere';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import StarIcon from '@mui/icons-material/Star';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { API } from 'aws-amplify';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  BookmarkData,
  CompletedSecData,
  CourseCategoryData,
  CourseData,
} from '../types';
import { captureError } from '../utils/capture-error';
import { bookmarkForCourse, progressForCourse } from '../utils/course-progress';
import { CourseCategoryChip } from './course-category-chip';
import { CourseLevelIcon } from './course-level-icon';

export function CourseTile(props: {
  item: CourseData;
  categories: CourseCategoryData[];
  bookmarks?: BookmarkData[];
  completedSecs?: CompletedSecData[];
  user?: CognitoUser | undefined;
}) {
  // State
  const [bookmark, setBookmark] = React.useState<BookmarkData>();
  const [bookmarked, setBookmarked] = React.useState(false);
  const [bookmarking, setBookmarking] = React.useState(false);

  React.useEffect(() => {
    if (props.bookmarks && props.bookmarks.length) {
      const bookmarkStatus = bookmarkForCourse({
        course: props.item,
        bookmarks: props.bookmarks,
      });
      if (bookmarkStatus.bookmarked) {
        setBookmark(bookmarkStatus.bookmark);
        setBookmarked(true);
      }
    }
  }, [props.item, props.bookmarks]);

  const handleBookmark = async () => {
    try {
      setBookmarking(true);
      if (bookmarked && bookmark) {
        // Remove bookmark
        await API.post('UniversityAPI', '/courses/bookmarks/remove', {
          body: { BookmarkId: bookmark.BookmarkId },
        });
        // Remove bookmark from state
        setBookmark(undefined);
        setBookmarked(false);
      } else {
        // Bookmark course
        await API.post('UniversityAPI', '/courses/bookmarks/add', {
          body: { CourseId: props.item.CourseId },
        });
        // Add the new bookmark into state
        const responseBookmarks: {
          data: BookmarkData[];
        } = await API.post('UniversityAPI', '/courses/bookmarks', {});
        const bookmarkStatus = bookmarkForCourse({
          course: props.item,
          bookmarks: responseBookmarks.data,
        });
        if (bookmarkStatus.bookmarked) {
          setBookmark(bookmarkStatus.bookmark);
          setBookmarked(true);
        }
      }
    } catch (error) {
      captureError({ data: { error } });
    } finally {
      setBookmarking(false);
    }
  };

  let bottomRightContent: JSX.Element | null = null;
  if (Boolean(props.user)) {
    const courseProgress = progressForCourse({
      course: props.item,
      completedSecs: props.completedSecs || [],
    });
    if (courseProgress.completed) {
      bottomRightContent = (
        <Avatar sx={{ backgroundColor: '#ffffff11' }}>
          <BeenhereIcon sx={{ color: '#00c853' }} />
        </Avatar>
      );
    } else if (courseProgress.progress !== 0) {
      bottomRightContent = (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress
            variant="determinate"
            value={100}
            sx={{ color: '#ffffff22' }}
          />
          <CircularProgress
            variant="determinate"
            value={courseProgress.progress}
            sx={{ position: 'absolute' }}
          />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant="caption"
              component="div"
              color="text.secondary"
              sx={{ fontWeight: 'bold' }}
            >
              {`${courseProgress.progress}%`}
            </Typography>
          </Box>
        </Box>
      );
    } else if (props.item.Required) {
      bottomRightContent = null;
    } else {
      bottomRightContent = (
        <Tooltip
          title={bookmarked ? 'Remove bookmark' : 'Bookmark course'}
          placement="left"
        >
          <span>
            <IconButton
              disabled={bookmarking}
              aria-label="bookmark"
              onClick={handleBookmark}
            >
              {bookmarking ? (
                <CircularProgress size={24} sx={{ color: '#2196f3' }} />
              ) : bookmarked ? (
                <BookmarkIcon sx={{ color: '#2196f3' }} />
              ) : (
                <BookmarkBorderIcon sx={{ color: '#2196f3' }} />
              )}
            </IconButton>
          </span>
        </Tooltip>
      );
    }
  }

  // Description
  let description: string | null = props.item.Description;
  if (props.item.Description && props.item.Description.length >= 120) {
    description = props.item.Description.slice(0, 120).trim() + '...';
  }

  return (
    <Box
      sx={{
        display: 'block',
        position: 'relative',
        height: 264,
        width: '100%',
        borderRadius: 2,
        backgroundColor: '#3D4349',
        backgroundImage: props.item.Image ? `url(${props.item.Image})` : 'none',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        overflow: 'hidden',
        transition: 'all 0.2s',
        ':hover': {
          transform: 'scale(1.05)',
        },
      }}
    >
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: props.item.Image ? '#000000bb' : 'inherit',
          border: '4px solid transparent',
          borderStyle: 'inset',
          borderRadius: 2,
          transition: 'all 0.2s',
          ':hover': {
            border: '4px solid #ffffff77',
          },
        }}
      >
        <Box sx={{ position: 'absolute', top: 8, left: 8 }}>
          <Box
            sx={{
              fontFamily: 'Bebas Neue',
              fontSize: 24,
              lineHeight: 1,
              marginRight: 1,
            }}
          >
            {props.item.CourseNo}
          </Box>
        </Box>

        <Box
          sx={{
            position: 'absolute',
            top: 6,
            right: 8,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {props.item.Certificate ? (
            <WorkspacePremiumIcon color="info" />
          ) : null}
          {props.item.Required ? (
            <StarIcon sx={{ color: 'yellow', marginLeft: 1 }} />
          ) : null}
          {props.item.Level !== null ? (
            <Box sx={{ marginLeft: 1, display: 'inline' }}>
              <CourseLevelIcon course={props.item} />
            </Box>
          ) : null}
        </Box>

        {props.item.CategoryId ? (
          <Box
            sx={{
              position: 'absolute',
              bottom: 6,
              left: 8,
            }}
          >
            <CourseCategoryChip
              size="small"
              course={props.item}
              categories={props.categories}
            />
          </Box>
        ) : null}

        {props.user ? (
          <Box
            sx={{
              position: 'absolute',
              bottom: 6,
              right: 8,
              display: 'flex',
            }}
          >
            {/* {progressForCourse({
              course: props.item,
              completedSecs: props.completedSecs || [],
            }).completed && props.item.Certificate ? (
              <Box sx={{ marginRight: 0.5 }}>
                <CourseDownload
                  user={props.user}
                  course={props.item}
                  placement="left"
                />
              </Box>
            ) : null} */}

            {bottomRightContent}
          </Box>
        ) : null}

        <Box
          component={Link}
          to={`/courses/${props.item.CourseSlug}`}
          sx={{
            paddingLeft: 1,
            paddingRight: 1,
            textDecorationLine: 'none',
            color: '#fff',
          }}
        >
          <Box
            sx={{
              fontFamily: 'Bebas Neue',
              lineHeight: 1,
              fontSize: 32,
              textAlign: 'center',
              marginBottom: 1,
              textShadow: '0 0 2px rgba(0,0,0,0.8)',
            }}
          >
            {props.item.Title}
          </Box>

          {description ? (
            <Box sx={{ fontSize: 16, textAlign: 'center' }}>{description}</Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
}
