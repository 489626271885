import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import orderBy from 'lodash/orderBy';
import { Link, useLocation } from 'react-router-dom';
import { DisplayMarkup } from '../../components/display-markup';
import { RouteContainer } from '../../components/route-container';
import { RouteHeader } from '../../components/route-header';
import { QuotingToolData } from '../../types';
import { captureError } from '../../utils/capture-error';

export function CarrierTools() {
  // Hooks
  const location = useLocation();

  // Query - Carrier Quoting Tools
  const path = '/quoting-tools';
  const query = useQuery({
    queryKey: [path],
    queryFn: async () => {
      const response: {
        data: QuotingToolData[];
      } = await API.post('UniversityAPI', path, {});

      if (response.data.length) {
        return response.data;
      }

      return [];
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const tools = orderBy(query.data || [], 'OrderIndex');

  const title = 'Quoting Tools';

  return (
    <RouteContainer
      loading={query.isLoading}
      hasError={query.isError}
      routeTitle={title}
      headerContent={<RouteHeader mainText={title} />}
    >
      <Container sx={{ paddingTop: 2, paddingBottom: 4 }}>
        <Grid container spacing={2}>
          {tools.map((tool) => {
            const id = tool.Title?.toLowerCase()
              .replaceAll(/[^a-zA-Z0-9\s]/g, '-')
              .replace(/\s/g, '-');

            if (location.hash === `#${id}`) {
              return (
                <Grid key={tool.ToolId} id={id} item xs={12}>
                  <Paper sx={{ borderLeft: `8px solid ${tool.Color}` }}>
                    <Typography
                      variant="h5"
                      component="div"
                      sx={{ padding: 2, fontWeight: 'bold' }}
                    >
                      {tool.Title}
                    </Typography>

                    <Box sx={{ padding: 1, paddingTop: 0 }}>
                      <Box
                        sx={{
                          backgroundColor: '#444',
                          border: '1px solid #ffffff11',
                          borderRadius: 2,
                          paddingLeft: 2,
                          paddingRight: 2,
                        }}
                      >
                        <DisplayMarkup
                          markup={tool.Description}
                          sx={{ a: { color: 'aqua' } }}
                        />
                      </Box>
                    </Box>

                    <Box sx={{ padding: 1, paddingTop: 0 }}>
                      {orderBy(tool.QuotingFiles, 'OrderIndex').map((file) => {
                        return (
                          <Box
                            key={file.FileId}
                            component="a"
                            href={file.Source || ''}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                              marginBottom: 1,
                              backgroundColor: '#333',
                              borderRadius: 2,
                              padding: 1,
                              display: 'flex',
                              alignItems: 'center',
                              color: '#fff',
                              textDecorationLine: 'none',
                              transition: 'all 0.2s',
                              ':hover': {
                                transform: 'scale(1.01)',
                                backgroundColor: '#363636',
                              },
                            }}
                          >
                            <Box sx={{ marginRight: 1 }}>
                              <ArrowCircleDownIcon
                                fontSize="large"
                                sx={{ display: 'block', color: 'aqua' }}
                              />
                            </Box>

                            <Box sx={{ flex: 1, fontSize: 20, paddingLeft: 1 }}>
                              {file.FileName}
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  </Paper>
                </Grid>
              );
            } else {
              return (
                <Grid key={tool.ToolId} id={id} item xs={12}>
                  <Paper
                    component={Link}
                    to={`/resources/carrier-tools#${id}`}
                    sx={{
                      height: '100%',
                      border: 'none',
                      borderLeft: `8px solid ${tool.Color}`,
                      display: 'block',
                      width: '100%',
                      cursor: 'pointer',
                      textAlign: 'left',
                      textDecorationLine: 'none',
                      transition: 'all 0.2s',
                      ':hover': {
                        transform: 'scale(1.01)',
                        backgroundColor: '#ffffff22',
                      },
                    }}
                  >
                    <Typography
                      variant="h5"
                      component="div"
                      sx={{ padding: 2, fontWeight: 'bold' }}
                    >
                      {tool.Title}
                    </Typography>
                  </Paper>
                </Grid>
              );
            }
          })}
        </Grid>
      </Container>
    </RouteContainer>
  );
}
