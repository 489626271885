import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import orderBy from 'lodash/orderBy';
import { DisplayMarkup } from '../../components/display-markup';
import { RouteContainer } from '../../components/route-container';
import { RouteHeader } from '../../components/route-header';
import { PGInsuranceData } from '../../types';
import { captureError } from '../../utils/capture-error';

export function ProductGuide() {
  // Query - Product Pocket Guide
  const path = '/pocket-guide';
  const query = useQuery({
    queryKey: [path],
    queryFn: async () => {
      const response: {
        data: PGInsuranceData[];
      } = await API.post('UniversityAPI', path, {});

      if (response.data.length) {
        return response.data;
      }

      return [];
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const types = orderBy(query.data || [], 'OrderIndex');

  return (
    <RouteContainer
      loading={query.isLoading}
      hasError={query.isError}
      routeTitle="Product Pocket Guide"
      headerContent={<RouteHeader mainText="Product Pocket Guide" />}
    >
      <Container sx={{ paddingTop: 2, paddingBottom: 2 }}>
        {types.map((type) => {
          const itemsOrdered = orderBy(type.Products, 'OrderIndex');

          if (type.Products.length) {
            return (
              <Accordion key={type.TypeId}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`type-conent-${type.TypeId}`}
                  id={`type-header-${type.TypeId}`}
                >
                  <Typography variant="h6" component="div">
                    {type.Title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {itemsOrdered.map((item) => {
                    return (
                      <Accordion
                        key={item.ProductId}
                        sx={{ backgroundColor: '#3a3a3a' }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`product-conent-${item.ProductId}`}
                          id={`product-header-${item.ProductId}`}
                        >
                          <Typography variant="h6" component="div">
                            {item.Title}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ backgroundColor: '#2a2a2a' }}>
                          <DisplayMarkup
                            sx={{ a: { color: 'aqua' } }}
                            markup={item.Description}
                          />
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </AccordionDetails>
              </Accordion>
            );
          } else {
            return null;
          }
        })}
      </Container>
    </RouteContainer>
  );
}
