import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as AllianceLogo } from '../assets/alliance-footer.svg';

interface FooterLink {
  Title: string;
  href?: string;
  to?: string;
}

function FooterLinks(props: { links: FooterLink[] }) {
  return (
    <Box sx={{ textAlign: { xs: 'center', md: 'inherit' } }}>
      {props.links.map((link, index) => {
        const fontWeight: React.CSSProperties['fontWeight'] =
          index === 0 ? 'bold' : 'normal';
        const fontSize: React.CSSProperties['fontWeight'] =
          index === 0 ? 16 : 14;
        if (link.href) {
          return (
            <Box
              key={link.Title}
              component="a"
              href={link.href}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                fontWeight,
                fontSize,
                display: 'block',
                textDecorationLine: 'none',
                color: '#fff',
                ':hover': {
                  textDecorationColor: 'aqua',
                  textDecorationLine: 'underline',
                },
              }}
            >
              {link.Title}
            </Box>
          );
        } else if (link.to) {
          return (
            <Box
              key={link.Title}
              component={Link}
              to={link.to}
              sx={{
                fontWeight,
                fontSize,
                display: 'block',
                textDecorationLine: 'none',
                color: '#fff',
                ':hover': {
                  textDecorationColor: 'aqua',
                  textDecorationLine: 'underline',
                },
              }}
            >
              {link.Title}
            </Box>
          );
        } else {
          return (
            <Box key={link.Title} sx={{ fontWeight, fontSize }}>
              {link.Title}
            </Box>
          );
        }
      })}
    </Box>
  );
}

export function Footer() {
  return (
    <Box sx={{ width: '100%', backgroundColor: '#000' }}>
      <Container sx={{ paddingTop: 6, paddingBottom: 8 }}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={2}>
            <FooterLinks
              links={[
                { Title: 'Connect' },
                { Title: 'The Alliance' },
                { Title: '1214 Turrentine Street' },
                { Title: 'Burlington, NC 27215' },
                { Title: '336.227.3319' },
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <FooterLinks
              links={[
                { Title: 'Tours' },
                {
                  Title: 'Quality Leads',
                  href: 'https://www.naaleads.com/tour/leads',
                },
                {
                  Title: 'Professional Training',
                  href: 'https://www.naaleads.com/tour/training',
                },
                {
                  Title: 'Turnkey System',
                  href: 'https://www.naaleads.com/tour/turnkey-system',
                },
                {
                  Title: 'Reliable Products',
                  href: 'https://www.naaleads.com/tour/products',
                },
                {
                  Title: 'Compensation',
                  href: 'https://www.naaleads.com/tour/compensation',
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <Box sx={{ textAlign: 'center' }}>
              <Link
                to="/"
                aria-label="home"
                style={{ display: 'inline-block', textDecorationLine: 'none' }}
              >
                <AllianceLogo style={{ width: 240, height: 125 }} />
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <FooterLinks
              links={[
                { Title: 'About' },
                {
                  Title: 'About The Alliance',
                  href: 'https://www.naaleads.com/about',
                },
                {
                  Title: 'Our Story',
                  href: 'https://www.naaleads.com/about/our-story',
                },
                {
                  Title: 'Leadership',
                  href: 'https://www.naaleads.com/about/our-leadership',
                },
                {
                  Title: 'Impact',
                  href: 'https://www.naaleads.com/about/charitable-partners',
                },
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <FooterLinks
              links={[
                { Title: 'Other' },
                {
                  Title: 'Corporate Jobs',
                  href: 'https://www.naaleads.com/corporate-jobs',
                },
                {
                  Title: 'Contact Us',
                  href: 'https://www.naaleads.com/contact',
                },
                { Title: 'Sitemap', href: 'https://www.naaleads.com/sitemap' },
                {
                  Title: 'Privacy Policy',
                  href: 'https://a7d9021b860b18c4222a-d761a6fa6590560fc9f9e4d318d2556e.ssl.cf1.rackcdn.com/contracting/documents/alliance-privacy-notice.pdf',
                },
                {
                  Title: 'NAA Terms of Service',
                  href: 'https://a7d9021b860b18c4222a-d761a6fa6590560fc9f9e4d318d2556e.ssl.cf1.rackcdn.com/contracting/documents/alliance-terms-of-service.pdf',
                },
                {
                  Title: 'Do Not Sell My Information',
                  href: 'https://submit-irm.trustarc.com/services/validation/d14968e7-edec-4831-b5f2-c494567844f6',
                },
              ]}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
