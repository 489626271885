import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { animated, useSpring } from '@react-spring/web';
import React from 'react';

export function BackToTop(props: {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) {
  // Hooks
  const values = useSpring({
    opacity: 1,
    transform: 'translate3d(0, 0, 0)',
    from: { opacity: 0, transform: 'translate3d(0, 10px, 0)' },
  });

  return (
    <animated.button
      aria-label="back to top"
      style={{
        backgroundColor: '#fff',
        cursor: 'pointer',
        height: 60,
        width: 60,
        borderRadius: 50,
        border: 'none',
        boxShadow: '0 5px 4px 0 rgba(0, 0, 0, .26)',
        position: 'fixed',
        left: 20,
        bottom: 20,
        zIndex: 1102,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        ...values,
      }}
      onClick={props.onClick}
    >
      <ArrowUpwardIcon color="primary" fontSize="large" />
    </animated.button>
  );
}
