import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { VideoCarrierData, VideoData, VideoProductData } from '../types';

function DisplayTitle(props: {
  Title: string | null;
  type: 'product' | 'carrier';
  isLink?: boolean;
}) {
  if (props.Title) {
    // React router dom uses plus signs for spaces in the URL
    const encodedURI = encodeURIComponent(props.Title).replace(/%20/g, '+');
    const to = `/videos?${props.type}=${encodedURI}`;

    return (
      <Box
        component={props.isLink ? Link : 'div'}
        to={props.isLink ? to : undefined}
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          padding: 1,
          fontWeight: 'bold',
          fontSize: 12,
          cursor: props.isLink ? 'pointer' : 'inherit',
          textDecorationLine: 'none',
          color: '#fff',
        }}
      >
        {props.Title}
      </Box>
    );
  } else {
    return null;
  }
}

export function DisplayTitleProduct(props: {
  ProductId: VideoData['ProductId'];
  products: VideoProductData[];
  isLink?: boolean;
}) {
  // Props
  const { ProductId } = props;

  if (ProductId) {
    // Find the product by its Id
    const product = props.products.find((i) => i.ProductId === ProductId);
    if (product) {
      return (
        <DisplayTitle
          isLink={props.isLink}
          type="product"
          Title={product.Title}
        />
      );
    }
  }
  return null;
}

export function DisplayTitleCarrier(props: {
  CarrierId: VideoData['CarrierId'];
  carriers: VideoCarrierData[];
  isLink?: boolean;
}) {
  // Props
  const { CarrierId } = props;

  if (CarrierId) {
    // Find the carrier by its Id
    const carrier = props.carriers.find((i) => i.CarrierId === CarrierId);
    if (carrier) {
      return (
        <DisplayTitle
          isLink={props.isLink}
          type="carrier"
          Title={carrier.Title}
        />
      );
    }
  }
  return null;
}
