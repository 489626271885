import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { ResourceData } from '../../types';

export function ResourceTile(props: { item: ResourceData }) {
  // Props
  const { item } = props;

  // Resource can be a link to an external page or a file
  const resourceLink: string = item.URL || item.Source || '';

  return (
    <Paper
      component="a"
      href={resourceLink}
      target="_blank"
      rel="noopener noreferrer"
      sx={{
        height: 264,
        backgroundColor: '#3D4349',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        textDecorationLine: 'none',
        transition: 'all 0.2s',
        border: `4px solid #ffffff22`,
        borderStyle: 'inset',
        ':hover': {
          transform: 'scale(1.05)',
          border: `4px solid #ffffff77`,
        },
      }}
    >
      <Box
        sx={{
          flex: 1,
          backgroundColor: '#3D4349',
          backgroundImage: item.Image ? `url(${item.Image})` : 'none',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          borderRadius: '2px 2px 0 0',
        }}
      />
      <Box sx={{ flex: 1, paddingTop: 1, paddingLeft: 1, paddingRight: 1 }}>
        <Box
          sx={{
            fontSize: 28,
            fontFamily: 'Bebas Neue',
            lineHeight: 1,
          }}
        >
          {item.Title}
        </Box>
        <Box sx={{ paddingTop: 0.4 }}>{item.Description}</Box>
      </Box>
    </Paper>
  );
}
