import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import ButtonBase from '@mui/material/ButtonBase';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useQueryClient } from '@tanstack/react-query';
import { Auth } from 'aws-amplify';
import React from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../contexts/app-context';
import { AuthContext } from '../../contexts/auth-context';
import { usePermissions } from '../../hooks/use-permissions';
import { signIn } from '../../utils/authenticate';
import { captureError } from '../../utils/capture-error';
import { navData } from './nav-data';

export function NavDrawer() {
  // Context
  const appContext = React.useContext(AppContext);
  const { state } = React.useContext(AuthContext);
  // Hooks
  const { checkPermissions } = usePermissions();

  const queryClient = useQueryClient();
  const handleSignOut = async () => {
    // Remove any cached data
    queryClient.removeQueries();
    // Sign out current user from Cognito
    await Auth.signOut();
    // After being signed out from Cognito
    // Hub.listen > 'auth' > 'signOut' will remove user  data from Context
  };

  const handleSignIn = async () => {
    try {
      await signIn({ window });
    } catch (error) {
      captureError({ data: { error } });
    }
  };

  return (
    <Drawer
      open={appContext.state.drawerOpen}
      anchor="left"
      variant="temporary"
      onClose={() => appContext.dispatch({ type: 'TOGGLE_DRAWER' })}
    >
      <div
        style={{
          width: 320,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            onClick={() => appContext.dispatch({ type: 'TOGGLE_DRAWER' })}
            aria-label="close menu"
          >
            <CloseIcon fontSize="large" />
          </IconButton>

          <Typography variant="h6" style={{ marginLeft: 10 }}>
            Menu
          </Typography>
        </div>

        <Divider />

        <div style={{ flex: 1, overflowY: 'scroll' }}>
          <List component="nav">
            {navData.map((item) => {
              let canView = true;
              if (item.authenticated) {
                if (item.permissions) {
                  canView = checkPermissions(item.permissions);
                } else {
                  canView = state.user !== undefined;
                }
              }

              if (canView) {
                return (
                  <ListItem key={item.to} button component={Link} to={item.to}>
                    <ListItemText
                      primary={
                        <Typography
                          style={{
                            fontFamily: 'Bebas Neue',
                            textTransform: 'uppercase',
                            fontSize: 32,
                            marginLeft: 10,
                          }}
                        >
                          {item.title}
                        </Typography>
                      }
                    />
                  </ListItem>
                );
              } else {
                return null;
              }
            })}
          </List>
        </div>

        <Divider />

        <div style={{ display: 'flex', alignItems: 'center' }}>
          {state.user !== undefined ? (
            <ButtonBase
              focusRipple
              style={{ flex: 1, justifyContent: 'flex-start', padding: 10 }}
              onClick={handleSignOut}
            >
              <ArrowBackIcon fontSize="large" />

              <Typography
                variant="body1"
                style={{ fontSize: 24, marginLeft: 10 }}
              >
                Logout {state.user.getUsername()}
              </Typography>
            </ButtonBase>
          ) : (
            <ButtonBase
              focusRipple
              style={{ flex: 1, justifyContent: 'flex-start', padding: 10 }}
              onClick={handleSignIn}
            >
              <ArrowForwardIcon fontSize="large" />

              <Typography
                variant="body1"
                style={{ fontSize: 24, marginLeft: 10 }}
              >
                Sign in
              </Typography>
            </ButtonBase>
          )}
        </div>
      </div>
    </Drawer>
  );
}
