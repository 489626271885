import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { API } from 'aws-amplify';
import orderBy from 'lodash/orderBy';
import React from 'react';
import { useParams } from 'react-router-dom';
import { RouteContainer } from '../../components/route-container';
import { RouteHeader } from '../../components/route-header';
import {
  GridConditionData,
  GridProductData,
  GridStatusData,
  GridTypeData,
} from '../../types';
import { captureError } from '../../utils/capture-error';

const getGridTitle = (gridId: string) => {
  switch (gridId) {
    case 'final-expense':
      return 'Final Expense';
    case 'mortgage-term':
      return 'Mortgage Term';
    case 'critical-illness':
      return 'Critical Illness';
    case 'hospital-indemnity':
      return 'Hospital Indemnity';
    default:
      return 'Underwriting';
  }
};

const getTypeId = (gridId: string, grids: GridTypeData[]) => {
  const GridTitle = getGridTitle(gridId);
  const Grid = grids.find((i) => i.Title === GridTitle);
  if (Grid) {
    return Grid.TypeId;
  } else {
    return null;
  }
};

export function Grid() {
  // State
  const [loading, setLoading] = React.useState(true);
  const [hasError, setHasError] = React.useState(false);
  const [conditions, setConditions] = React.useState<GridConditionData[]>([]);
  const [products, setProducts] = React.useState<GridProductData[]>([]);
  const [statuses, setStatuses] = React.useState<GridStatusData[]>([]);
  // Hooks
  const { gridId } = useParams<{ gridId: string }>();

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response: {
          data: {
            Types: GridTypeData[];
            Conditions: GridConditionData[];
            Products: GridProductData[];
            Statuses: GridStatusData[];
          };
        } = await API.post('UniversityAPI', '/grids', {});

        const TypeId = getTypeId(gridId || '', response.data.Types);
        if (TypeId !== null) {
          setConditions(
            orderBy(response.data.Conditions, [(i) => i.Title.toLowerCase()])
          );
          setProducts(
            orderBy(
              response.data.Products.filter((i) => i.TypeId === TypeId),
              'OrderIndex'
            )
          );
          const statusesWithData = response.data.Statuses.filter(
            (i) => i.Result !== '' || i.Status !== ''
          );
          setStatuses(statusesWithData.filter((i) => i.TypeId === TypeId));
        }
      } catch (error) {
        setHasError(true);
        captureError({ data: { error } });
      } finally {
        setLoading(false);
      }
    };

    if (gridId) {
      fetchData();
    }
  }, [gridId]);

  let title = 'Grid';
  if (gridId) {
    title = getGridTitle(gridId) + ' Grid';
  }

  return (
    <RouteContainer
      loading={loading}
      hasError={hasError}
      routeTitle={title}
      headerContent={<RouteHeader mainText={title} />}
    >
      <Container sx={{ paddingTop: 2, paddingBottom: 2 }}>
        {conditions.map((condition, index) => {
          const hasStatusItems =
            statuses.filter((i) => i.ConditionId === condition.ConditionId)
              .length > 0;

          if (hasStatusItems) {
            return (
              <Accordion key={condition.ConditionId}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`conent-${condition.ConditionId}`}
                  id={`header-${condition.ConditionId}`}
                >
                  <Typography variant="h6" component="div">
                    {condition.Title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {products.map((product) => {
                    const status = statuses.find(
                      (i) =>
                        i.ProductId === product.ProductId &&
                        i.ConditionId === condition.ConditionId
                    );
                    if (status) {
                      let colorResults: React.CSSProperties['color'] =
                        'lightblue';
                      if (status.Result?.toLowerCase().includes('ok')) {
                        colorResults = 'lightgreen';
                      } else if (
                        status.Result?.toLowerCase().includes('decline')
                      ) {
                        colorResults = 'tomato';
                      }

                      return (
                        <Box
                          key={product.ProductId}
                          sx={{
                            borderBottom: '1px solid #ffffff11',
                            backgroundColor: '#3a3a3a',
                            padding: '5px 10px',
                            display: 'flex',
                            alignItems: 'center',
                            borderLeftWidth: 2,
                            borderLeftStyle: 'solid',
                            borderLeftColor: colorResults,
                          }}
                        >
                          <Box sx={{ flex: 1 }}>
                            <Box sx={{ fontSize: 16, fontWeight: 'bold' }}>
                              {product.Title}
                            </Box>
                            <Box sx={{ fontSize: 14 }}>{status.Status}</Box>
                          </Box>
                          <Box
                            sx={{
                              fontSize: 12,
                              fontWeight: 'bold',
                              color: colorResults,
                              padding: 1,
                            }}
                          >
                            {status.Result}
                          </Box>
                        </Box>
                      );
                    } else {
                      return null;
                    }
                  })}
                </AccordionDetails>
              </Accordion>
            );
          } else {
            return null;
          }
        })}
      </Container>
    </RouteContainer>
  );
}
