export function searchBy<T>(
  collection: Array<T>,
  searchString: string
): Array<T> {
  if (searchString.trim() !== '') {
    return collection.filter((i) => {
      let valueString = '';

      // Combines all values of an object into a single string
      // @ts-ignore
      Object.values(i).forEach((value) => {
        if (value !== null) {
          valueString = valueString + ' ' + value?.toString();
        }
      });

      return valueString.toLowerCase().includes(searchString.toLowerCase());
    });
  } else {
    return collection;
  }
}
