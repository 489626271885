import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { TransitionProps } from '@mui/material/transitions';
import { useQuery } from '@tanstack/react-query';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { API } from 'aws-amplify';
import format from 'date-fns/format';
import html2canvas from 'html2canvas';
import React from 'react';
import { ReactComponent as AllianceLogo } from '../../assets/alliance-university-print.svg';
import { ReactComponent as AllianceSeal } from '../../assets/alliance-university-seal.svg';
import { CourseData } from '../../types';
import { captureError } from '../../utils/capture-error';
import { isAgt } from '../../utils/is-agent';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function CourseCertificateAML(props: {
  user: CognitoUser | undefined;
  course: CourseData;
  courseCompleted: boolean;
}) {
  // Props
  const { courseCompleted } = props;
  // State
  const [open, setOpen] = React.useState(false);

  // Query - Course Certificate
  const path = '/courses/certificate/generate';
  const query = useQuery({
    enabled: open,
    queryKey: [path, { CourseId: props.course.CourseId }],
    queryFn: async () => {
      const response: {
        data: {
          Username: string | null;
          AgtName: string | null;
          CourseId: string | null;
          CompletedDate: string | null;
        };
      } = await API.post('UniversityAPI', path, {
        body: { CourseId: props.course.CourseId },
      });

      return response.data;
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const takeScreenshotAndDownload = () => {
    // ID of the element to screenshot
    const element = document.getElementById('capture');

    if (element) {
      html2canvas(element).then((canvas) => {
        // Create an image from the canvas
        const image = canvas.toDataURL('image/png', 1.0);

        // Create a link to download the image
        let downloadLink = document.createElement('a');
        downloadLink.href = image;
        downloadLink.download = 'alliance-aml-certificate.png';

        // Append the link to the body and click it to trigger the download
        document.body.appendChild(downloadLink);
        downloadLink.click();

        // Clean up by removing the link
        document.body.removeChild(downloadLink);
      });
    }
  };

  // Only Agents can generate course certificates
  const isAgent = isAgt(props.user?.getUsername());

  if (isAgent) {
    return (
      <React.Fragment>
        <Paper sx={{ padding: 2, display: 'flex', alignItems: 'center' }}>
          <WorkspacePremiumIcon fontSize="large" color="info" />

          {courseCompleted ? (
            <Box sx={{ flex: 1, fontSize: 20, marginLeft: 1 }}>
              Download your AML certificate.
            </Box>
          ) : (
            <Box sx={{ flex: 1, fontSize: 20, marginLeft: 1 }}>
              Complete the AML course and then download your certificate.
            </Box>
          )}

          <Button
            disabled={!courseCompleted}
            size="large"
            variant="contained"
            color="info"
            startIcon={
              courseCompleted ? (
                <CheckCircleOutlineIcon color="inherit" />
              ) : (
                <RadioButtonUncheckedIcon color="inherit" />
              )
            }
            onClick={handleClickOpen}
          >
            View and Download Certificate
          </Button>
        </Paper>

        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                AML Certificate
              </Typography>
              <Button
                disabled={query.isLoading || query.isError}
                autoFocus
                color="info"
                size="large"
                variant="contained"
                onClick={takeScreenshotAndDownload}
                startIcon={<DownloadIcon />}
              >
                Download Certificate
              </Button>
            </Toolbar>
          </AppBar>

          {query.isLoading ? (
            <Box sx={{ backgroundColor: '#fff', height: '100%' }}>
              <LinearProgress />
            </Box>
          ) : (
            <Box
              sx={{
                backgroundColor: '#fff',
                height: '100%',
                overflow: 'scroll',
              }}
            >
              <Container
                id="capture"
                maxWidth="sm"
                sx={{ p: 1, color: '#000' }}
              >
                <Box sx={{ height: '100%', border: '4px solid #000', p: 1 }}>
                  <Box
                    sx={{
                      height: '100%',
                      border: '2px solid #000',
                      p: 1,
                      py: 4,
                    }}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Box sx={{ textAlign: 'center' }}>
                          <AllianceLogo style={{ width: 256, height: 96 }} />
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Box
                          sx={{
                            textAlign: 'center',
                            fontFamily: 'Bebas Neue',
                            color: '#000',
                          }}
                        >
                          <Box sx={{ fontSize: 36, lineHeight: 3 }}>
                            Anti-money laundering training
                          </Box>
                          <Box sx={{ fontSize: 48, lineHeight: 0.8 }}>
                            Certificate
                          </Box>
                          <Box sx={{ fontSize: 24 }}>of completion</Box>
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Box sx={{ py: 2, textAlign: 'center', color: '#000' }}>
                          <Box sx={{ fontSize: 14, fontWeight: 'bold' }}>
                            This certificate acknowledges that
                          </Box>
                          <Box
                            sx={{
                              fontSize: 28,
                              fontWeight: 'bold',
                              fontFamily: 'cursive',
                              maxWidth: 380,
                              borderBottom: '1px solid #000',
                              margin: '0 auto',
                              color: '#3D4349',
                            }}
                          >
                            {query.data?.AgtName || props.user?.getUsername()}
                          </Box>
                          <Box
                            sx={{
                              maxWidth: 380,
                              fontSize: 14,
                              fontWeight: 'bold',
                              margin: '0 auto',
                            }}
                          >
                            has successfully completed the Anti-Money Laundering
                            Training Course for agents provided by National
                            Agents Alliance through Alliance University.
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Box sx={{ textAlign: 'center' }}>
                          <AllianceSeal style={{ height: 192 }} />
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Box sx={{ textAlign: 'center' }}>
                          {query.data && query.data.CompletedDate ? (
                            <Box
                              sx={{
                                fontSize: 22,
                                fontWeight: 'bold',
                                fontFamily: 'cursive',
                                maxWidth: 220,
                                borderBottom: '1px solid #000',
                                margin: '0 auto',
                                color: '#3D4349',
                              }}
                            >
                              {format(
                                new Date(query.data.CompletedDate + 'T00:00'),
                                'PPP',
                              )}
                            </Box>
                          ) : null}

                          <Box sx={{ fontSize: 14, fontWeight: 'bold' }}>
                            Date of Completion
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <Box sx={{ textAlign: 'center' }}>
                          <Box
                            sx={{
                              fontSize: 22,
                              fontFamily: 'Roboto Mono',
                              fontWeight: 'bold',
                              maxWidth: 220,
                              borderBottom: '1px solid #000',
                              margin: '0 auto',
                              color: '#3D4349',
                            }}
                          >
                            {props.user?.getUsername()}
                          </Box>
                          <Box sx={{ fontSize: 14, fontWeight: 'bold' }}>
                            Agent Number
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Container>
            </Box>
          )}
        </Dialog>
      </React.Fragment>
    );
  } else {
    return null;
  }
}
