import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import YouTubeIcon from '@mui/icons-material/YouTube';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import moment from 'moment-timezone';
import React from 'react';

// useInterval from
// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
type IntervalFunction = () => unknown | void;
function useInterval(callback: IntervalFunction, delay: number) {
  const savedCallback = React.useRef<IntervalFunction | null>(null);

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  React.useEffect(() => {
    function tick() {
      if (savedCallback.current !== null) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

interface ConferenceCall {
  day: number;
  name: string;
  date: string;
  phone: string;
  code: string;
  youtube: string;
}

const conferenceCalls: ConferenceCall[] = [
  {
    day: 3,
    name: 'TWC',
    date: 'Wednesdays @ 12:30 PM ET',
    phone: '312-626-6799',
    code: '969 606 853#',
    youtube: 'https://www.youtube.com/theallianceyt',
  },
  {
    day: 4,
    name: 'Product Call',
    date: 'Thursdays @ 1:00 PM ET',
    phone: '253-215-8782',
    code: '875 5255 6016',
    youtube: 'https://us02web.zoom.us/j/87552556016',
  },
  {
    day: 5,
    name: 'Activity Call',
    date: 'Fridays @ 10:00 AM ET',
    phone: '312-626-6799',
    code: '969 606 853#',
    youtube: 'https://www.youtube.com/theallianceyt',
  },
];

function DisplayCall(props: { call: ConferenceCall }) {
  // Props
  const { call } = props;
  // State
  const [isLive, setIsLive] = React.useState(false);

  const getStatus = () => {
    const day = new Date().getDay();

    let hour = moment().tz('America/New_York').format('H');
    if (hour.toString().length < 2) {
      hour = `0${hour}`;
    }

    let minute = moment().tz('America/New_York').format('m');
    if (minute.toString().length < 2) {
      minute = `0${minute}`;
    }

    const timeOfDay = `${hour}:${minute}`;
    if (day === 3 && timeOfDay >= '12:15' && timeOfDay <= '14:00') {
      return true;
    } else if (day === 4 && timeOfDay >= '12:45' && timeOfDay <= '14:00') {
      return true;
    } else if (day === 5 && timeOfDay >= '09:45' && timeOfDay <= '11:00') {
      return true;
    } else {
      return false;
    }
  };

  React.useEffect(() => {
    setIsLive(getStatus());
  }, []);

  // 10 second delay between intervals
  const delay = 10 * 1000;
  useInterval(() => {
    setIsLive(getStatus());
  }, delay);

  return (
    <Box sx={{ paddingBottom: 4 }}>
      <Paper
        sx={{
          position: 'relative',
          padding: 2,
          borderWidth: 4,
          borderStyle: 'solid',
          borderColor: isLive ? 'red' : '#ffffff77',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Box
              sx={{ fontFamily: 'Bebas Neue', fontSize: 64, lineHeight: 0.8 }}
            >
              {call.name}
            </Box>
            <Box sx={{ fontSize: 18 }}>{call.date}</Box>
            <Box sx={{ fontSize: 14 }}>Listen: {call.phone}</Box>
            <Box sx={{ fontSize: 14 }}>Code: {call.code}</Box>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Box
              component="a"
              href={call.youtube}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                textDecorationLine: 'none',
                display: 'block',
                backgroundColor: '#3D4349',
                backgroundImage: `url(https://a7d9021b860b18c4222a-d761a6fa6590560fc9f9e4d318d2556e.ssl.cf1.rackcdn.com/university/conference-call-watch-641337bb8db96.jpg)`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                height: '100%',
                minHeight: 120,
                width: '100%',
                borderRadius: 2,
                overflow: 'hidden',
                transition: 'all 0.2s',
                ':hover': {
                  transform: 'scale(1.05)',
                },
              }}
            >
              <Box
                sx={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#000000bb',
                  transition: 'all 0.2s',
                  ':hover': {
                    backgroundColor: '#00000088',
                  },
                }}
              >
                <YouTubeIcon fontSize="large" color="secondary" />
                <Box sx={{ color: '#fff', fontSize: 24, fontWeight: 'bold' }}>
                  Watch live
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Box
              component="a"
              href={`tel:${call.phone},,,${call.code}`}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                textDecorationLine: 'none',
                display: 'block',
                backgroundColor: '#3D4349',
                backgroundImage: `url(https://a7d9021b860b18c4222a-d761a6fa6590560fc9f9e4d318d2556e.ssl.cf1.rackcdn.com/university/conference-call-zoom-641337c7983da.jpg)`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                height: '100%',
                minHeight: 120,
                width: '100%',
                borderRadius: 2,
                overflow: 'hidden',
                transition: 'all 0.2s',
                ':hover': {
                  transform: 'scale(1.05)',
                },
              }}
            >
              <Box
                sx={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#000000bb',
                  transition: 'all 0.2s',
                  ':hover': {
                    backgroundColor: '#00000088',
                  },
                }}
              >
                <HeadsetMicIcon fontSize="large" color="secondary" />
                <Box sx={{ color: '#fff', fontSize: 24, fontWeight: 'bold' }}>
                  Dial in
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>

        {isLive ? (
          <Box sx={{ position: 'absolute', top: -20, right: 10 }}>
            <Chip
              label="Live"
              color="primary"
              sx={{
                fontWeight: 'bold',
                textTransform: 'uppercase',
                backgroundColor: 'red',
              }}
            />
          </Box>
        ) : null}
      </Paper>
    </Box>
  );
}

export function ConferenceCalls() {
  return (
    <Box>
      {conferenceCalls.map((call) => {
        const currentDay = new Date().getDay();
        if (currentDay === call.day) {
          return <DisplayCall key={call.name} call={call} />;
        } else {
          return null;
        }
      })}
    </Box>
  );
}
