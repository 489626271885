import BeenhereIcon from '@mui/icons-material/Beenhere';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import StarIcon from '@mui/icons-material/Star';
import UnpublishedOutlinedIcon from '@mui/icons-material/UnpublishedOutlined';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { API } from 'aws-amplify';
import orderBy from 'lodash/orderBy';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { CourseCategoryChip } from '../../components/course-category-chip';
import {
  DisplayTitleCarrier,
  DisplayTitleProduct,
} from '../../components/course-filter-title';
import { CourseLevelIcon } from '../../components/course-level-icon';
import { RouteContainer } from '../../components/route-container';
import { SignInBanner } from '../../components/sign-in-banner';
import { AuthContext } from '../../contexts/auth-context';
import {
  BookmarkData,
  CompletedSecData,
  CourseCarrierData,
  CourseCategoryData,
  CourseData,
  CourseProductData,
  CourseSectionData,
} from '../../types';
import { captureError } from '../../utils/capture-error';
import {
  bookmarkForCourse,
  progressForCourse,
} from '../../utils/course-progress';
import { CourseCertificateAML } from './course-certificate-aml';
import { CourseSection } from './course-section';

export function Course() {
  // Context
  const { state } = React.useContext(AuthContext);
  // State
  const [loading, setLoading] = React.useState(true);
  const [hasError, setHasError] = React.useState(false);
  const [updating, setUpdating] = React.useState<number | null>(null);
  const [course, setCourse] = React.useState<CourseData>();
  const [categories, setCategories] = React.useState<CourseCategoryData[]>([]);
  const [products, setProducts] = React.useState<CourseProductData[]>([]);
  const [carriers, setCarriers] = React.useState<CourseCarrierData[]>([]);
  const [bookmark, setBookmark] = React.useState<BookmarkData>();
  const [bookmarked, setBookmarked] = React.useState(false);
  const [bookmarking, setBookmarking] = React.useState(false);
  const [sections, setSections] = React.useState<CourseSectionData[]>([]);
  const [completedSecs, setCompletedSecs] = React.useState<CompletedSecData[]>(
    [],
  );
  // Hooks
  const { courseId } = useParams<{ courseId: string }>();

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        setCourse(undefined);
        setBookmarked(false);
        setBookmarking(false);
        setSections([]);
        setUpdating(null);

        // Course
        const response: {
          data: CourseData[];
        } = await API.post('UniversityAPI', '/courses', {
          body: { CourseSlug: courseId },
        });
        if (response.data.length) {
          const courseData = response.data[0];
          setCourse(courseData);
          setSections(courseData.Sections);

          if (state.user) {
            // User Bookmarks
            const responseBookmarks: {
              data: BookmarkData[];
            } = await API.post('UniversityAPI', '/courses/bookmarks', {});
            const bookmarkStatus = bookmarkForCourse({
              course: courseData,
              bookmarks: responseBookmarks.data,
            });
            if (bookmarkStatus.bookmarked) {
              setBookmark(bookmarkStatus.bookmark);
              setBookmarked(true);
            }

            // User Sections Completed
            const responseCompleted: {
              data: CompletedSecData[];
            } = await API.post('UniversityAPI', '/sections/completed', {});
            setCompletedSecs(responseCompleted.data);
          }
        }

        // Course Filters
        const responseFilters: {
          data: {
            Categories: CourseCategoryData[];
            Products: CourseProductData[];
            Carriers: CourseCarrierData[];
          };
        } = await API.post('UniversityAPI', '/courses/filters', {});
        setCategories(
          responseFilters.data.Categories.map((i) => ({
            ...i,
            CategoryId: Number(i.CategoryId),
          })),
        );
        setProducts(
          responseFilters.data.Products.map((i) => ({
            ...i,
            ProductId: Number(i.ProductId),
          })),
        );
        setCarriers(
          responseFilters.data.Carriers.map((i) => ({
            ...i,
            CarrierId: Number(i.CarrierId),
          })),
        );
      } catch (error) {
        captureError({ data: { error } });
        setHasError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [courseId, state.user]);

  const handleComplete = async (item: CourseSectionData) => {
    try {
      setUpdating(item.SecId);
      const completedSec = completedSecs.find((i) => i.SecId === item.SecId);
      if (completedSec) {
        // Remove completed section
        await API.post('UniversityAPI', '/sections/completed/remove', {
          body: { CompletedId: completedSec.CompletedId },
        });
      } else {
        // Complete section
        await API.post('UniversityAPI', '/sections/completed/add', {
          body: { SecId: item.SecId },
        });
      }
      // User Sections Completed
      const responseCompleted: {
        data: CompletedSecData[];
      } = await API.post('UniversityAPI', '/sections/completed', {});
      setCompletedSecs(responseCompleted.data);
    } catch (error) {
      captureError({ data: { error } });
    } finally {
      setUpdating(null);
    }
  };

  const handleBookmark = async () => {
    try {
      setBookmarking(true);
      if (bookmarked && bookmark) {
        // Remove bookmark
        await API.post('UniversityAPI', '/courses/bookmarks/remove', {
          body: { BookmarkId: bookmark.BookmarkId },
        });
        // Remove bookmark from state
        setBookmark(undefined);
        setBookmarked(false);
      } else if (course) {
        // Bookmark course
        await API.post('UniversityAPI', '/courses/bookmarks/add', {
          body: { CourseId: course.CourseId },
        });
        // Add the new bookmark into state
        const responseBookmarks: {
          data: BookmarkData[];
        } = await API.post('UniversityAPI', '/courses/bookmarks', {});
        const bookmarkStatus = bookmarkForCourse({
          course: course,
          bookmarks: responseBookmarks.data,
        });
        if (bookmarkStatus.bookmarked) {
          setBookmark(bookmarkStatus.bookmark);
          setBookmarked(true);
        }
      }
    } catch (error) {
      captureError({ data: { error } });
    } finally {
      setBookmarking(false);
    }
  };

  let content = <div />;
  if (course) {
    let courseStatus: JSX.Element | null = null;
    const courseProgress = progressForCourse({ course, completedSecs });
    // The user is authenticated with Cognito
    const isAuthenticated = Boolean(state.user);
    // To view the content of the course you must be authenticated
    // Anyone can view the Getting Started course
    const canView = isAuthenticated || courseId === 'getting-started-quickly';

    if (isAuthenticated) {
      if (courseProgress.completed) {
        // Display Finished Icon and possible Download Icon
        courseStatus = (
          <Box sx={{ display: 'flex' }}>
            {/* {course.Certificate ? (
              <Box sx={{ marginRight: 1 }}>
                <CourseDownload user={state.user} course={course} />
              </Box>
            ) : null} */}

            <Avatar sx={{ backgroundColor: '#ffffff11' }}>
              <BeenhereIcon sx={{ color: '#00c853' }} />
            </Avatar>
          </Box>
        );
      } else if (courseProgress.progress !== 0) {
        // Display course Progress
        courseStatus = (
          <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress
              variant="determinate"
              value={100}
              sx={{ color: '#ffffff22' }}
            />
            <CircularProgress
              variant="determinate"
              value={courseProgress.progress}
              sx={{ position: 'absolute' }}
            />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography
                variant="caption"
                component="div"
                color="text.secondary"
                sx={{ fontWeight: 'bold' }}
              >
                {`${courseProgress.progress}%`}
              </Typography>
            </Box>
          </Box>
        );
      } else if (!course.Required) {
        // Display course Bookmark
        courseStatus = (
          <IconButton
            size="large"
            disabled={bookmarking}
            aria-label="bookmark"
            onClick={handleBookmark}
          >
            {bookmarking ? (
              <CircularProgress size={24} sx={{ color: '#2196f3' }} />
            ) : bookmarked ? (
              <BookmarkIcon sx={{ color: '#2196f3' }} />
            ) : (
              <BookmarkBorderIcon sx={{ color: '#2196f3' }} />
            )}
          </IconButton>
        );
      }
    }

    content = (
      <Grid container spacing={3} sx={{ paddingTop: 4, paddingBottom: 4 }}>
        <Grid item xs={12} md={course.Image ? 8 : 12}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ fontFamily: 'Bebas Neue', fontSize: 44 }}>
              {course.CourseNo} - {course.Title}
            </Box>

            {isAuthenticated ? (
              <Box sx={{ marginLeft: 1 }}>{courseStatus}</Box>
            ) : null}
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {course.CategoryId ? (
              <Box sx={{ padding: 1 }}>
                <CourseCategoryChip
                  isLink
                  size="small"
                  course={course}
                  categories={categories}
                />
              </Box>
            ) : null}

            {course.ProductId ? (
              <Box sx={{ padding: 1 }}>
                <DisplayTitleProduct
                  isLink
                  ProductId={course.ProductId}
                  products={products}
                />
              </Box>
            ) : null}

            {course.CarrierId ? (
              <Box sx={{ padding: 1 }}>
                <DisplayTitleCarrier
                  isLink
                  CarrierId={course.CarrierId}
                  carriers={carriers}
                />
              </Box>
            ) : null}
          </Box>

          <Box sx={{ fontSize: 18, paddingTop: 2, paddingBottom: 2 }}>
            {course.Description}
          </Box>

          {course.Level !== null ? (
            <Box sx={{ marginBottom: 2 }}>
              <CourseLevelIcon course={course} withDescription />
            </Box>
          ) : null}

          {course.Required ? (
            <Box
              sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}
            >
              <StarIcon sx={{ color: 'yellow' }} />
              <Box sx={{ marginLeft: 1 }}>This is a Required course</Box>
            </Box>
          ) : null}

          {course.Certificate ? (
            <Box
              sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}
            >
              <WorkspacePremiumIcon color="info" />
              <Box sx={{ marginLeft: 1 }}>
                This course comes with a Certificate
              </Box>
            </Box>
          ) : null}

          {!course.Published ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <UnpublishedOutlinedIcon sx={{ color: 'orange' }} />
              <Box sx={{ marginLeft: 1 }}>This course is not Published</Box>
            </Box>
          ) : null}
        </Grid>

        {course.Image ? (
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                height: 264,
                width: '100%',
                color: '#fff',
                borderRadius: 2,
                backgroundColor: '#3D4349',
                backgroundImage: `url(${course.Image})`,
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}
            />
          </Grid>
        ) : null}

        <Grid item xs={12}>
          {!isAuthenticated ? (
            <Box sx={{ marginBottom: 2 }}>
              <SignInBanner
                message={`Sign in to begin the ${course.Title} course`}
              />
            </Box>
          ) : null}

          {orderBy(sections, 'OrderIndex').map((item) => {
            return (
              <CourseSection
                key={item.SecId}
                item={item}
                completedSecs={completedSecs}
                canView={canView}
                isAuthenticated={isAuthenticated}
                updating={updating}
                onChange={() => handleComplete(item)}
              />
            );
          })}

          {courseProgress.completed ? (
            <Alert
              severity="success"
              variant="filled"
              icon={<BeenhereIcon />}
              sx={{ width: '100%', marginBottom: 2 }}
            >
              You have completed the {course.Title} course!
            </Alert>
          ) : null}

          {isAuthenticated &&
          (Number(course.CourseId) === 114 ||
            Number(course.CourseId) === 120) ? (
            <CourseCertificateAML
              user={state.user}
              course={course}
              courseCompleted={courseProgress.completed}
            />
          ) : null}

          {/* {isAuthenticated && course.Certificate ? (
            <CourseCertificate
              user={state.user}
              course={course}
              courseCompleted={courseProgress.completed}
            />
          ) : null} */}

          {!isAuthenticated ? (
            <SignInBanner
              message={`Sign in to begin the ${course.Title} course`}
            />
          ) : null}
        </Grid>
      </Grid>
    );
  } else {
    content = (
      <Box sx={{ textAlign: 'center', paddingTop: 8, paddingBottom: 8 }}>
        <Box sx={{ fontFamily: 'Bebas Neue', fontSize: 32 }}>
          This course was not found
        </Box>
        <Box sx={{ padding: 2 }}>
          <Link
            to="/courses"
            style={{ color: '#fff', textDecorationColor: '#00f', fontSize: 18 }}
          >
            View all courses
          </Link>
        </Box>
      </Box>
    );
  }

  let routeTitle = '';
  if (loading) {
    routeTitle = 'Loading...';
  } else if (course) {
    routeTitle = course.Title || 'Course';
  }

  return (
    <RouteContainer
      routeTitle={routeTitle}
      loading={loading}
      hasError={hasError}
    >
      <Container>{content}</Container>
    </RouteContainer>
  );
}
