import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import orderBy from 'lodash/orderBy';
import React from 'react';
import { VideoTile } from '../../components/video-tile';
import { AuthContext } from '../../contexts/auth-context';
import { VideoData, VideoSavedData } from '../../types';
import { captureError } from '../../utils/capture-error';

const latestVideos = (video: VideoData, items: VideoData[]) => {
  // Remove the current video
  const data = items.filter((i) => i.VideoId !== video.VideoId);

  const videos: VideoData[] = [];

  orderBy(data, 'UploadDate', 'desc').forEach((item, index) => {
    if (index < 9) {
      videos.push(item);
    }
  });

  return videos;
};

const savedVideos = (items: VideoData[], saves: VideoSavedData[]) => {
  const videos: VideoData[] = [];

  orderBy(saves, 'CreatedDate', 'desc').forEach((item) => {
    const savedVideo = items.find((i) => i.VideoId === item.VideoId);
    if (savedVideo) {
      videos.push(savedVideo);
    }
  });

  return videos;
};

export function FeaturedVideos(props: { video: VideoData }) {
  // Props
  const { video } = props;
  // Context
  const { state } = React.useContext(AuthContext);
  // State
  const [value, setValue] = React.useState(0);

  const isAuthenticated = state.user !== undefined;

  // Query - Videos
  const pathVideos = '/video-vault';
  const queryVideos = useQuery({
    enabled: isAuthenticated,
    queryKey: [pathVideos],
    queryFn: async () => {
      const response: {
        data: VideoData[];
      } = await API.post('UniversityAPI', pathVideos, {});

      if (response.data) {
        return response.data;
      } else {
        return [];
      }
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Query - Saved Videos
  const pathSaved = '/video-vault/saved-list';
  const querySaved = useQuery({
    enabled: isAuthenticated,
    queryKey: [pathSaved],
    queryFn: async () => {
      const response: {
        data: VideoSavedData[];
      } = await API.post('UniversityAPI', pathSaved, {});

      if (response.data) {
        return response.data;
      } else {
        return [];
      }
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const isLoading =
    (queryVideos.isLoading && queryVideos.fetchStatus !== 'idle') ||
    (querySaved.isLoading && querySaved.fetchStatus !== 'idle');

  let content = <div />;
  if (isLoading) {
    content = (
      <Box>
        {Array.from({ length: 9 }).map((_, index) => {
          return (
            <Box key={index} sx={{ padding: 1 }}>
              <Skeleton
                variant="rectangular"
                height={108}
                sx={{ borderRadius: 2 }}
              />
            </Box>
          );
        })}
      </Box>
    );
  } else if (value === 0) {
    // Latest Videos
    content = (
      <Box>
        {latestVideos(video, queryVideos.data || []).map((item) => {
          return (
            <Box key={item.VideoId} sx={{ padding: 1 }}>
              <VideoTile video={item} flexDirection="row" />
            </Box>
          );
        })}
      </Box>
    );
  } else if (value === 1 && querySaved.data && querySaved.data.length === 0) {
    content = (
      <Box sx={{ padding: 2, textAlign: 'center' }}>
        <Box
          sx={{ fontFamily: 'Bebas Neue', fontSize: 28, color: '#ffffff99' }}
        >
          Saved videos will be listed here!
        </Box>
      </Box>
    );
  } else if (value === 1) {
    // Saved Videos
    content = (
      <Box>
        {savedVideos(queryVideos.data || [], querySaved.data || []).map(
          (item) => {
            return (
              <Box key={item.VideoId} sx={{ padding: 1 }}>
                <VideoTile video={item} flexDirection="row" />
              </Box>
            );
          }
        )}
      </Box>
    );
  }

  return (
    <Paper
      elevation={0}
      sx={{ backgroundColor: '#ffffff11', overflow: 'hidden' }}
    >
      <Box sx={{ backgroundColor: '#ffffff11' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          textColor="inherit"
          indicatorColor="primary"
        >
          <Tab label="Latest videos" />
          {state.user !== undefined ? <Tab label="Saved videos" /> : null}
        </Tabs>
      </Box>

      <Box sx={{ height: '64vh', overflow: 'scroll', padding: 1 }}>
        {content}
      </Box>
    </Paper>
  );
}
