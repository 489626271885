import { CognitoUser, CognitoUserSession } from 'amazon-cognito-identity-js';
import React, { Dispatch } from 'react';

// State
type AuthState = {
  user: CognitoUser | undefined;
  session: CognitoUserSession | undefined;
  isChecking: boolean;
  tokenError: boolean;
};
const initialState: AuthState = {
  user: undefined,
  session: undefined,
  isChecking: true,
  tokenError: false,
};

// Actions
type AuthActions =
  | { type: 'USER_CLEAR' }
  | { type: 'TOKEN_ERROR' }
  | { type: 'USER_SET'; payload: CognitoUser }
  | { type: 'SESSION_SET'; payload: CognitoUserSession }
  | { type: 'SET_CHECKING'; payload: boolean };

const reducer = (state: AuthState, action: AuthActions) => {
  switch (action.type) {
    case 'USER_CLEAR': {
      return {
        ...state,
        user: undefined,
        session: undefined,
        tokenError: false,
      };
    }
    case 'TOKEN_ERROR': {
      return { ...state, tokenError: true };
    }
    case 'USER_SET': {
      return { ...state, user: action.payload };
    }
    case 'SESSION_SET': {
      return { ...state, session: action.payload };
    }
    case 'SET_CHECKING': {
      return { ...state, isChecking: action.payload };
    }
    default: {
      return { ...state };
    }
  }
};

export const AuthContext = React.createContext(
  {} as {
    state: AuthState;
    dispatch: Dispatch<AuthActions>;
  }
);

export function AuthProvider(props: { children: React.ReactNode }) {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {props.children}
    </AuthContext.Provider>
  );
}
