import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { API } from 'aws-amplify';
import format from 'date-fns/format';
import formatDuration from 'format-duration';
import React from 'react';
import { Link } from 'react-router-dom';
import { VideoCategoryData, VideoData, VideoSavedData } from '../types';
import { captureError } from '../utils/capture-error';
import { videoSavedStatus } from '../utils/videos';
import { VideoCategoryChip } from './video-category-chip';

export function VideoTile(props: {
  video: VideoData;
  categories?: VideoCategoryData[];
  flexDirection: React.CSSProperties['flexDirection'];
  saves?: VideoSavedData[];
  user?: CognitoUser | undefined;
}) {
  // Props
  const { video } = props;
  // State
  const [save, setSave] = React.useState<VideoSavedData>();
  const [saved, setSaved] = React.useState(false);
  const [saving, setSaving] = React.useState(false);

  React.useEffect(() => {
    if (props.saves && props.saves.length) {
      const savedStatus = videoSavedStatus({
        video: props.video,
        saves: props.saves,
      });
      if (savedStatus.saved) {
        setSave(savedStatus.save);
        setSaved(true);
      }
    }
  }, [props.video, props.saves]);

  const handleSave = async () => {
    try {
      setSaving(true);
      if (saved && save) {
        // Remove video from My Learning
        await API.post('UniversityAPI', '/video-vault/unsave-video', {
          body: { SavedId: save.SavedId },
        });
        setSave(undefined);
        setSaved(false);
      } else {
        // Save the video to My Learning
        await API.post('UniversityAPI', '/video-vault/save-video', {
          body: { VideoId: props.video.VideoId },
        });
        // Add the new saved video into state
        const responseSaves: {
          data: VideoSavedData[];
        } = await API.post('UniversityAPI', '/video-vault/saved-list', {});
        const savedStatus = videoSavedStatus({
          video: props.video,
          saves: responseSaves.data,
        });
        if (savedStatus.saved) {
          setSave(savedStatus.save);
          setSaved(true);
        }
      }
    } catch (error) {
      captureError({ data: { error } });
    } finally {
      setSaving(false);
    }
  };

  const Thumbnail = video.ThumbnailFile || video.ThumbnailUrl;

  const to = `/videos/${video.VideoSlug}`;

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        alignItems: props.flexDirection === 'row' ? 'center' : 'inherit',
        flexDirection: props.flexDirection || 'column',
        height: '100%',
        borderRadius: 2,
        border: '4px solid #1a1a1a',
        backgroundColor: '#222',
        overflow: 'hidden',
        transition: 'all 0.2s',
        ':hover': {
          transform: 'scale(1.05)',
          border: '4px solid #772929',
        },
        ':hover .video-item-reveal': {
          background: 'transparent',
        },
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Link
          to={to}
          style={{
            display: 'block',
            height: '100%',
            textDecorationLine: 'none',
          }}
        >
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              aspectRatio: '16 / 9',
              backgroundImage: Thumbnail ? `url(${Thumbnail})` : 'none',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
            }}
          >
            <Box
              className="video-item-reveal"
              sx={{
                position: 'absolute',
                top: 0,
                width: '100%',
                height: '100%',
                background: '#00000066',
                transition: 'all 0.2s',
              }}
            />

            {video.UploadDate ? (
              <Box
                sx={{
                  position: 'absolute',
                  top: 8,
                  left: 8,
                  color: '#fff',
                  fontSize: 11,
                  fontWeight: 'bold',
                  backgroundColor: '#00000077',
                  borderRadius: 2,
                  padding: '2px 6px',
                }}
              >
                {format(
                  new Date(video.UploadDate),
                  'yyyy MMM dd'
                ).toUpperCase()}
              </Box>
            ) : null}

            {video.Duration ? (
              <Box
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  color: '#fff',
                  fontSize: 11,
                  fontWeight: 'bold',
                  backgroundColor: '#00000077',
                  borderRadius: 2,
                  padding: '2px 6px',
                }}
              >
                {formatDuration(video.Duration * 1000)}
              </Box>
            ) : null}

            {video.CategoryId && props.categories ? (
              <Box sx={{ position: 'absolute', bottom: 8, left: 8 }}>
                <VideoCategoryChip
                  video={video}
                  size="small"
                  categories={props.categories}
                />
              </Box>
            ) : null}
          </Box>
        </Link>
      </Box>

      <Box
        sx={{
          flex: 0.8,
          display: 'flex',
          alignItems: 'flex-start',
        }}
      >
        <Link
          to={to}
          style={{
            flex: 1,
            display: 'block',
            textDecorationLine: 'none',
          }}
        >
          <Box
            sx={{
              color: '#fff',
              fontFamily: 'Bebas Neue',
              fontSize: props.flexDirection === 'row' ? 16 : 20,
              padding: 1,
            }}
          >
            {video.Title}
          </Box>
        </Link>

        {props.flexDirection === 'column' && props.user ? (
          <Box sx={{ padding: 1 }}>
            <Tooltip
              title={saved ? 'Remove from My Learning' : 'Save to My Learning'}
              placement="bottom"
            >
              <span>
                <IconButton
                  disabled={saving}
                  size="small"
                  aria-label="save video"
                  onClick={handleSave}
                >
                  {saving ? (
                    <CircularProgress size={24} color="primary" />
                  ) : saved ? (
                    <BookmarkIcon color="primary" />
                  ) : (
                    <BookmarkBorderIcon color="primary" />
                  )}
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}
