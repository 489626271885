import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import React from 'react';
import { AnimateIn } from './animate-in';

export function RouteHeader(props: { mainText: string; smallText?: string }) {
  return (
    <Paper elevation={0} sx={{ borderRadius: 0 }}>
      <AnimateIn>
        <Container sx={{ padding: 2, paddingTop: 3 }}>
          {props.smallText ? (
            <Box
              sx={{
                fontFamily: 'Bebas Neue',
                fontSize: 36,
                color: '#000',
                lineHeight: 1,
              }}
            >
              {props.smallText}
            </Box>
          ) : null}
          <Box sx={{ fontFamily: 'Bebas Neue', fontSize: 80, lineHeight: 1 }}>
            {props.mainText}
          </Box>
        </Container>
      </AnimateIn>
    </Paper>
  );
}
