import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { API } from 'aws-amplify';
import React from 'react';
import { VideoData, VideoSavedData } from '../../types';
import { captureError } from '../../utils/capture-error';
import { videoSavedStatus } from '../../utils/videos';

export function SaveToMyLearning(props: {
  video: VideoData;
  saves: VideoSavedData[];
  user: CognitoUser | undefined;
  onSave: () => void;
}) {
  // State
  const [save, setSave] = React.useState<VideoSavedData>();
  const [saved, setSaved] = React.useState(false);
  const [saving, setSaving] = React.useState(false);

  React.useEffect(() => {
    if (props.saves && props.saves.length) {
      const savedStatus = videoSavedStatus({
        video: props.video,
        saves: props.saves,
      });
      if (savedStatus.saved) {
        setSave(savedStatus.save);
        setSaved(true);
      }
    }
  }, [props.video, props.saves]);

  const handleSave = async () => {
    try {
      setSaving(true);
      if (saved && save) {
        // Remove video from My Learning
        await API.post('UniversityAPI', '/video-vault/unsave-video', {
          body: { SavedId: save.SavedId },
        });
        setSave(undefined);
        setSaved(false);
      } else {
        // Save the video to My Learning
        await API.post('UniversityAPI', '/video-vault/save-video', {
          body: { VideoId: props.video.VideoId },
        });
        // Add the new saved video into state
        const responseSaves: {
          data: VideoSavedData[];
        } = await API.post('UniversityAPI', '/video-vault/saved-list', {});
        const savedStatus = videoSavedStatus({
          video: props.video,
          saves: responseSaves.data,
        });
        if (savedStatus.saved) {
          setSave(savedStatus.save);
          setSaved(true);
        }
      }

      // Trigger a refetch of the sidebar videos
      props.onSave();
    } catch (error) {
      captureError({ data: { error } });
    } finally {
      setSaving(false);
    }
  };

  let buttonText = 'Save to My Learning';
  let buttonIcon = <BookmarkBorderIcon />;
  if (!saved && saving) {
    buttonText = 'Saving...';
    buttonIcon = <CircularProgress size={18} color="secondary" />;
  } else if (saved && saving) {
    buttonText = 'Removing...';
    buttonIcon = <CircularProgress size={18} color="secondary" />;
  } else if (saved) {
    buttonText = 'Remove from My Learning';
    buttonIcon = <BookmarkIcon />;
  }

  return (
    <Button
      disabled={saving}
      size="small"
      variant="contained"
      disableElevation
      startIcon={buttonIcon}
      onClick={handleSave}
      sx={{ whiteSpace: 'nowrap' }}
    >
      {buttonText}
    </Button>
  );
}
