import { Outlet } from 'react-router-dom';
import { Navigation } from './navigation';
import { TokenError } from './token-error';

export function Dashboard() {
  return (
    <div>
      <Navigation />

      <TokenError />

      <Outlet />
    </div>
  );
}
