import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import orderBy from 'lodash/orderBy';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { DisplayMarkup } from '../../components/display-markup';
import { RouteContainer } from '../../components/route-container';
import { SignInBanner } from '../../components/sign-in-banner';
import { VideoCategoryChip } from '../../components/video-category-chip';
import {
  DisplayTitleCarrier,
  DisplayTitleProduct,
} from '../../components/video-filter-title';
import { VideoFrame } from '../../components/video-frame';
import { AuthContext } from '../../contexts/auth-context';
import {
  VideoCarrierData,
  VideoCategoryData,
  VideoData,
  VideoProductData,
  VideoSavedData,
} from '../../types';
import { captureError } from '../../utils/capture-error';
import { FeaturedVideos } from './featured-videos';
import { SaveToMyLearning } from './save-to-my-learning';

export function Video() {
  // Context
  const { state } = React.useContext(AuthContext);
  // Hooks
  const { videoId } = useParams<{ videoId: string }>();

  const queryClient = useQueryClient();

  const isAuthenticated = state.user !== undefined;

  // Query - Videos
  const pathVideos = '/video-vault';
  const queryVideos = useQuery({
    enabled: isAuthenticated,
    queryKey: [pathVideos, { VideoSlug: videoId }],
    queryFn: async () => {
      const response: {
        data: VideoData[];
      } = await API.post('UniversityAPI', pathVideos, {
        body: { VideoSlug: videoId },
      });

      if (response.data) {
        return response.data;
      } else {
        return [];
      }
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Query - Video Filters
  const pathFilters = '/video-vault/filters';
  const queryFilters = useQuery({
    enabled: isAuthenticated,
    queryKey: [pathFilters],
    queryFn: async () => {
      const response: {
        data: {
          Categories: VideoCategoryData[];
          Products: VideoProductData[];
          Carriers: VideoCarrierData[];
        };
      } = await API.post('UniversityAPI', pathFilters, {});

      if (response.data) {
        return response.data;
      } else {
        return { Categories: [], Products: [], Carriers: [] };
      }
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Query - Saved Videos
  const pathSaved = '/video-vault/saved-list';
  const querySaved = useQuery({
    enabled: isAuthenticated,
    queryKey: [pathSaved],
    queryFn: async () => {
      const response: {
        data: VideoSavedData[];
      } = await API.post('UniversityAPI', pathSaved, {});

      if (response.data) {
        return response.data;
      } else {
        return [];
      }
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Categories
  const dataCategories = queryFilters.data
    ? queryFilters.data.Categories.map((i) => ({
        ...i,
        CategoryId: Number(i.CategoryId),
      }))
    : [];
  // Products
  const dataProducts = queryFilters.data
    ? queryFilters.data.Products.map((i) => ({
        ...i,
        ProductId: Number(i.ProductId),
      }))
    : [];
  // Carriers
  const dataCarriers = queryFilters.data
    ? queryFilters.data.Carriers.map((i) => ({
        ...i,
        CarrierId: Number(i.CarrierId),
      }))
    : [];

  let video: VideoData | undefined = undefined;
  if (queryVideos.data && queryVideos.data.length) {
    video = queryVideos.data[0];
  }

  let content = <div />;
  if (!isAuthenticated) {
    content = (
      <Container maxWidth="lg">
        <Stack spacing={4}>
          <SignInBanner message="Sign in to view this Video" />

          <Paper
            elevation={0}
            sx={{
              height: 480,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <PlayCircleOutlineIcon sx={{ fontSize: 84, opacity: 0.6 }} />
          </Paper>

          <Paper
            elevation={0}
            sx={{
              height: 280,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <InfoOutlinedIcon sx={{ fontSize: 48, opacity: 0.6 }} />
          </Paper>

          <SignInBanner message="Sign in to view this Video" />
        </Stack>
      </Container>
    );
  } else if (video) {
    content = (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Button
            component={Link}
            to="/videos"
            variant="contained"
            disableElevation
            startIcon={<ArrowBackIcon />}
            sx={{
              backgroundColor: '#3D4349',
              color: '#fff',
              ':hover': {
                backgroundColor: '#333',
              },
            }}
          >
            Back to Video Vault
          </Button>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {video.EmbedUrl ? (
                <VideoFrame
                  src={video.EmbedUrl}
                  title={video.Title || 'Alliance University Video'}
                />
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: {
                    xs: 'column',
                    md: 'row',
                  },
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  sx={{
                    fontFamily: 'Bebas Neue',
                    fontSize: 36,
                    marginRight: 1,
                  }}
                >
                  {video.Title}
                </Box>

                {state.user !== undefined ? (
                  <Box>
                    <SaveToMyLearning
                      video={video}
                      saves={querySaved.data || []}
                      user={state.user}
                      onSave={async () => {
                        // Invalidate all videos
                        await queryClient.invalidateQueries({
                          queryKey: [pathVideos],
                        });

                        // Invalidate all saves
                        await queryClient.invalidateQueries({
                          queryKey: [pathSaved],
                        });
                      }}
                    />
                  </Box>
                ) : null}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {video.CategoryId ? (
                  <Box sx={{ paddingRight: 1 }}>
                    <VideoCategoryChip
                      isLink
                      size="small"
                      video={video}
                      categories={dataCategories}
                    />
                  </Box>
                ) : null}

                {video.ProductId ? (
                  <Box sx={{ paddingRight: 1 }}>
                    <DisplayTitleProduct
                      isLink
                      ProductId={video.ProductId}
                      products={dataProducts}
                    />
                  </Box>
                ) : null}

                {video.CarrierId ? (
                  <Box sx={{ paddingRight: 1 }}>
                    <DisplayTitleCarrier
                      isLink
                      CarrierId={video.CarrierId}
                      carriers={dataCarriers}
                    />
                  </Box>
                ) : null}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <DisplayMarkup
                markup={video.Description}
                sx={{ a: { color: 'aqua' } }}
              />
            </Grid>
            {orderBy(video.Sections, 'OrderIndex').map((section) => {
              return (
                <Grid key={section.SectionId} item xs={12}>
                  <Paper>
                    <Box sx={{ padding: 2 }}>
                      <Box
                        sx={{
                          fontFamily: 'Bebas Neue',
                          fontSize: 32,
                          color: '#ffffff99',
                        }}
                      >
                        {section.Title}
                      </Box>

                      <DisplayMarkup
                        markup={section.Description}
                        sx={{ a: { color: 'aqua' } }}
                      />
                    </Box>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <FeaturedVideos video={video} />
        </Grid>
      </Grid>
    );
  } else {
    content = (
      <Box sx={{ textAlign: 'center', paddingTop: 8, paddingBottom: 8 }}>
        <Box sx={{ fontFamily: 'Bebas Neue', fontSize: 32 }}>
          This video was not found
        </Box>
        <Box sx={{ padding: 2 }}>
          <Link
            to="/videos"
            style={{ color: '#fff', textDecorationColor: '#00f', fontSize: 18 }}
          >
            View all videos
          </Link>
        </Box>
      </Box>
    );
  }

  const isLoading =
    (queryVideos.isLoading && queryVideos.fetchStatus !== 'idle') ||
    (queryFilters.isLoading && queryFilters.fetchStatus !== 'idle') ||
    (querySaved.isLoading && querySaved.fetchStatus !== 'idle');

  const isError =
    queryVideos.isError || queryFilters.isError || querySaved.isError;

  let routeTitle = '';
  if (isLoading) {
    routeTitle = 'Loading...';
  } else if (video) {
    routeTitle = video.Title || 'Video';
  }

  return (
    <RouteContainer
      routeTitle={routeTitle}
      loading={isLoading}
      hasError={isError}
    >
      <Container maxWidth="xl" sx={{ paddingTop: 4, paddingBottom: 4 }}>
        {content}
      </Container>
    </RouteContainer>
  );
}
