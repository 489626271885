import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import SearchIcon from '@mui/icons-material/Search';
import StarIcon from '@mui/icons-material/Star';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { API } from 'aws-amplify';
import orderBy from 'lodash/orderBy';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { CourseTile } from '../../components/course-tile';
import { RouteContainer } from '../../components/route-container';
import { RouteHeader } from '../../components/route-header';
import { AuthContext } from '../../contexts/auth-context';
import {
  BookmarkData,
  CompletedSecData,
  CourseCarrierData,
  CourseCategoryData,
  CourseData,
  CourseProductData,
} from '../../types';
import { captureError } from '../../utils/capture-error';
import { progressForLevel } from '../../utils/course-progress';
import { searchBy } from '../../utils/search-by';

interface CoursesLevelData {
  Key: string;
  Color: string;
  Title: string;
  Description: string;
  Progress: number;
  Icon: JSX.Element;
}

export function Courses() {
  // Context
  const { state } = React.useContext(AuthContext);
  // State
  const [loading, setLoading] = React.useState(true);
  const [hasError, setHasError] = React.useState(false);
  const [required, setRequired] = React.useState(false);
  const [category, setCategory] = React.useState('All');
  const [product, setProduct] = React.useState('All');
  const [carrier, setCarrier] = React.useState('All');
  const [level, setLevel] = React.useState<string | null>(null);
  const [levels, setLevels] = React.useState<CoursesLevelData[]>([]);
  const [items, setItems] = React.useState<CourseData[]>([]);
  const [categories, setCategories] = React.useState<CourseCategoryData[]>([]);
  const [products, setProducts] = React.useState<CourseProductData[]>([]);
  const [carriers, setCarriers] = React.useState<CourseCarrierData[]>([]);
  const [bookmarks, setBookmarks] = React.useState<BookmarkData[]>([]);
  const [completedSecs, setCompletedSecs] = React.useState<CompletedSecData[]>(
    []
  );
  const [searchString, setSearchString] = React.useState('');
  // Hooks
  const [search, setSearch] = useSearchParams();

  React.useEffect(() => {
    // Category filter for courses
    if (search.get('category') !== null) {
      setCategory(search.get('category') || 'All');
    } else {
      setCategory('All');
    }
    // Product filter for courses
    if (search.get('product') !== null) {
      setProduct(search.get('product') || 'All');
    } else {
      setProduct('All');
    }
    // Carrier filter for courses
    if (search.get('carrier') !== null) {
      setCarrier(search.get('carrier') || 'All');
    } else {
      setCarrier('All');
    }
  }, [search]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        // Courses
        const response: {
          data: CourseData[];
        } = await API.post('UniversityAPI', '/courses', {
          body: { PublishedOnly: 1 },
        });
        setItems(response.data);

        // Course Filters
        const responseFilters: {
          data: {
            Categories: CourseCategoryData[];
            Products: CourseProductData[];
            Carriers: CourseCarrierData[];
          };
        } = await API.post('UniversityAPI', '/courses/filters', {});
        setCategories(
          responseFilters.data.Categories.map((i) => ({
            ...i,
            CategoryId: Number(i.CategoryId),
          }))
        );
        setProducts(
          responseFilters.data.Products.map((i) => ({
            ...i,
            ProductId: Number(i.ProductId),
          }))
        );
        setCarriers(
          responseFilters.data.Carriers.map((i) => ({
            ...i,
            CarrierId: Number(i.CarrierId),
          }))
        );

        if (state.user) {
          // User Bookmarks
          const responseBookmarks: {
            data: BookmarkData[];
          } = await API.post('UniversityAPI', '/courses/bookmarks', {});
          setBookmarks(responseBookmarks.data);

          // User Sections Completed
          const responseCompleted: {
            data: CompletedSecData[];
          } = await API.post('UniversityAPI', '/sections/completed', {});
          setCompletedSecs(responseCompleted.data);

          setLevels([
            {
              Key: 'Beginner',
              Color: '#00e676',
              Title: 'Rookie Agent',
              Description:
                'These courses are designed specifically for newer agents to help them learn about products quickly. These videos will help you better understand the basics to help you get started.',
              Progress: progressForLevel({
                courses: response.data,
                completedSecs: responseCompleted.data,
                Level: 'Beginner',
              }).progress,
              Icon: <Chip label="R" size="small" sx={{ color: '#fff' }} />,
            },
            {
              Key: 'Advanced',
              Color: '#76ff03',
              Title: 'Advanced Agent',
              Description:
                'This series of videos are geared toward agents who have made sales and ready to take their business to the next level.',
              Progress: progressForLevel({
                courses: response.data,
                completedSecs: responseCompleted.data,
                Level: 'Advanced',
              }).progress,
              Icon: <Chip label="A" size="small" sx={{ color: '#fff' }} />,
            },
            {
              Key: 'Veteran',
              Color: '#c6ff00',
              Title: 'Veteran Agent',
              Description:
                'These courses are for veteran agents who have been with The Alliance and have reached a point where they are helping clients with products like annuities and IULs.',
              Progress: progressForLevel({
                courses: response.data,
                completedSecs: responseCompleted.data,
                Level: 'Veteran',
              }).progress,
              Icon: <Chip label="V" size="small" sx={{ color: '#fff' }} />,
            },
          ]);
        }
      } catch (error) {
        captureError({ data: { error } });
        setHasError(true);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [state.user]);

  const handleNavigate = (params: {
    category: string;
    product: string;
    carrier: string;
  }) => {
    const newParams: any = {};
    if (params.category !== 'All') {
      newParams['category'] = params.category;
    }
    if (params.product !== 'All') {
      newParams['product'] = params.product;
    }
    if (params.carrier !== 'All') {
      newParams['carrier'] = params.carrier;
    }
    setSearch(newParams);
  };

  // Categories for Select
  const allCategories = orderBy(categories.map((i) => i.Title));
  const selectCategories = ['All', ...allCategories];
  // Products for Select
  const allProducts = orderBy(products.map((i) => i.Title));
  const selectProducts = ['All', ...allProducts];
  // Carriers for Select
  const allCarriers = orderBy(carriers.map((i) => i.Title));
  const selectCarriers = ['All', ...allCarriers];

  // Filter by Level
  let levelItems = items;
  if (level !== null) {
    levelItems = items.filter((i) => i.Level === level);
  }
  // Filter by search
  let searchItems = levelItems;
  if (searchString !== '') {
    searchItems = searchBy(levelItems, searchString);
  }
  // Fitler by required
  let requiredItems = searchItems;
  if (required) {
    requiredItems = searchItems.filter((i) => i.Required);
  }
  // Filter by categories
  let categoryItems = requiredItems;
  if (category !== 'All') {
    const selectedCategory = categories.find((i) => i.Title === category);
    categoryItems = requiredItems.filter(
      (i) => i.CategoryId === selectedCategory?.CategoryId
    );
  }
  // Filter by products
  let productItems = categoryItems;
  if (product !== 'All') {
    const selectedProduct = products.find((i) => i.Title === product);
    productItems = categoryItems.filter(
      (i) => i.ProductId === selectedProduct?.ProductId
    );
  }
  // Filter by carriers
  let carrierItems = productItems;
  if (carrier !== 'All') {
    const selectedCarrier = carriers.find((i) => i.Title === carrier);
    carrierItems = productItems.filter(
      (i) => i.CarrierId === selectedCarrier?.CarrierId
    );
  }
  // Disabled the Required Switch if there are no required courses for the category
  const disableSwitch = carrierItems.filter((i) => i.Required).length === 0;
  // Order the courses
  const orderedItems = orderBy(carrierItems, 'CourseNo');

  return (
    <RouteContainer
      routeTitle="Courses"
      loading={loading}
      hasError={hasError}
      headerContent={<RouteHeader mainText="Courses" />}
    >
      <div>
        <Container>
          <Grid container spacing={4} sx={{ paddingTop: 4, paddingBottom: 4 }}>
            {levels.map((item) => {
              const isSelected = item.Key === level;
              return (
                <Grid key={item.Key} item xs={12} sm={6} md={4}>
                  <Paper
                    elevation={0}
                    sx={{
                      height: '100%',
                      background: 'none',
                      border: '1px solid #ffffff11',
                      padding: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <Box sx={{ fontFamily: 'Bebas Neue', fontSize: 32 }}>
                      {item.Title}
                    </Box>

                    {Boolean(state.user) ? (
                      <Box sx={{ paddingTop: 1, paddingBottom: 1 }}>
                        <Box
                          sx={{ position: 'relative', display: 'inline-flex' }}
                        >
                          <CircularProgress
                            size={200}
                            variant="determinate"
                            value={100}
                            sx={{ color: '#ffffff11' }}
                          />

                          <CircularProgress
                            size={200}
                            color="secondary"
                            variant="determinate"
                            value={item.Progress}
                            sx={{ position: 'absolute', color: item.Color }}
                          />

                          <Box
                            sx={{
                              top: 0,
                              left: 0,
                              bottom: 0,
                              right: 0,
                              position: 'absolute',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Typography
                              variant="caption"
                              component="div"
                              color="text.secondary"
                              sx={{ fontSize: 48 }}
                            >{`${item.Progress}%`}</Typography>
                          </Box>
                        </Box>
                      </Box>
                    ) : null}

                    <Box sx={{ flex: 1, paddingTop: 1, paddingBottom: 1 }}>
                      {item.Description}
                    </Box>

                    <Button
                      fullWidth
                      variant={isSelected ? 'outlined' : 'contained'}
                      disableElevation
                      startIcon={
                        isSelected ? <CancelOutlinedIcon /> : item.Icon
                      }
                      onClick={() => {
                        if (isSelected) {
                          setLevel(null);
                        } else {
                          const element =
                            document.getElementById('category-select');
                          element?.scrollIntoView();
                          setLevel(item.Key);
                        }
                        handleNavigate({
                          category: 'All',
                          product: 'All',
                          carrier: 'All',
                        });
                        setRequired(false);
                      }}
                    >
                      {isSelected ? 'View All' : 'View Courses'}
                    </Button>
                  </Paper>
                </Grid>
              );
            })}

            <Grid item xs={12}>
              <TextField
                id="courses-search"
                variant="outlined"
                placeholder="Search courses"
                type="search"
                color="secondary"
                fullWidth
                value={searchString}
                onChange={(event) => setSearchString(event.target.value)}
                sx={{ backgroundColor: '#ffffff11' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth color="secondary">
                <InputLabel id="category-select-label">Category</InputLabel>
                <Select
                  labelId="category-select-label"
                  id="category-select"
                  value={category}
                  label="Category"
                  onChange={(event) => {
                    const value = event.target.value as string;
                    handleNavigate({ category: value, product, carrier });
                    setRequired(false);
                  }}
                >
                  {selectCategories.map((item) => {
                    let count = levelItems.length;
                    if (item !== 'All') {
                      const selectedCategory = categories.find(
                        (i) => i.Title === item
                      );
                      count = levelItems.filter(
                        (i) => i.CategoryId === selectedCategory?.CategoryId
                      ).length;
                    }
                    return (
                      <MenuItem key={item} value={item}>
                        {item} ({count})
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth color="secondary">
                <InputLabel id="product-select-label">Product</InputLabel>
                <Select
                  labelId="product-select-label"
                  id="product-select"
                  value={product}
                  label="Product"
                  onChange={(event) => {
                    const value = event.target.value as string;
                    handleNavigate({ category, product: value, carrier });
                    setRequired(false);
                  }}
                >
                  {selectProducts.map((item) => {
                    let count = levelItems.length;
                    if (item !== 'All') {
                      const selectedProduct = products.find(
                        (i) => i.Title === item
                      );
                      count = levelItems.filter(
                        (i) => i.ProductId === selectedProduct?.ProductId
                      ).length;
                    }
                    return (
                      <MenuItem key={item} value={item}>
                        {item} ({count})
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <FormControl fullWidth color="secondary">
                <InputLabel id="carrier-select-label">Carrier</InputLabel>
                <Select
                  labelId="carrier-select-label"
                  id="carrier-select"
                  value={carrier}
                  label="Carrier"
                  onChange={(event) => {
                    const value = event.target.value as string;
                    handleNavigate({ category, product, carrier: value });
                    setRequired(false);
                  }}
                >
                  {selectCarriers.map((item) => {
                    let count = levelItems.length;
                    if (item !== 'All') {
                      const selectedCarrier = carriers.find(
                        (i) => i.Title === item
                      );
                      count = levelItems.filter(
                        (i) => i.CarrierId === selectedCarrier?.CarrierId
                      ).length;
                    }
                    return (
                      <MenuItem key={item} value={item}>
                        {item} ({count})
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <FormControlLabel
                  disabled={disableSwitch}
                  control={
                    <Switch
                      checked={required}
                      onChange={(event, checked) => setRequired(checked)}
                    />
                  }
                  label={
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography>View Required</Typography>
                      <StarIcon
                        sx={{ color: 'yellow', marginLeft: 1, opacity: 0.6 }}
                      />
                    </Box>
                  }
                />
              </Box>
            </Grid>

            {orderedItems.map((item) => {
              return (
                <Grid key={item.CourseId} item xs={12} sm={6} md={4}>
                  <CourseTile
                    item={item}
                    categories={categories}
                    user={state.user}
                    bookmarks={bookmarks}
                    completedSecs={completedSecs}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </div>
    </RouteContainer>
  );
}
