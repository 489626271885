import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { CourseData } from '../types';

export function CourseLevelIcon(props: {
  course: CourseData;
  withDescription?: boolean;
}) {
  if (props.withDescription) {
    switch (props.course.Level) {
      case 'Beginner':
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Chip
              label="R"
              size="small"
              sx={{
                fontSize: 18,
                fontWeight: 'bold',
                backgroundColor: '#00e67677',
              }}
            />
            <Box sx={{ marginLeft: 1 }}>This is a Rookie course</Box>
          </Box>
        );
      case 'Advanced':
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Chip
              label="A"
              size="small"
              sx={{
                fontSize: 18,
                fontWeight: 'bold',
                backgroundColor: '#76ff0377',
              }}
            />
            <Box sx={{ marginLeft: 1 }}>This is an Advanced course</Box>
          </Box>
        );
      case 'Veteran':
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2 }}>
            <Chip
              label="V"
              size="small"
              sx={{
                fontSize: 18,
                fontWeight: 'bold',
                backgroundColor: '#c6ff0077',
              }}
            />
            <Box sx={{ marginLeft: 1 }}>This is a Veteran course</Box>
          </Box>
        );
      default:
        return null;
    }
  } else {
    switch (props.course.Level) {
      case 'Beginner':
        return (
          <Chip
            label="R"
            size="small"
            sx={{
              fontSize: 18,
              fontWeight: 'bold',
              backgroundColor: '#00e67677',
            }}
          />
        );
      case 'Advanced':
        return (
          <Chip
            label="A"
            size="small"
            sx={{
              fontSize: 18,
              fontWeight: 'bold',
              backgroundColor: '#76ff0377',
            }}
          />
        );
      case 'Veteran':
        return (
          <Chip
            label="V"
            size="small"
            sx={{
              fontSize: 18,
              fontWeight: 'bold',
              backgroundColor: '#c6ff0077',
            }}
          />
        );
      default:
        return null;
    }
  }
}
