import { BookmarkData, CompletedSecData, CourseData } from '../types';

export const bookmarkForCourse = (params: {
  course: CourseData;
  bookmarks: BookmarkData[];
}) => {
  // Results to be returned
  const results: {
    bookmarked: boolean;
    bookmark: BookmarkData | undefined;
  } = {
    bookmarked: false,
    bookmark: undefined,
  };

  // Find this course in the users bookmarks
  const bookmark = params.bookmarks.find(
    (i) => i.CourseId === params.course.CourseId
  );

  // If the course has been found
  if (bookmark) {
    results.bookmarked = true;
    results.bookmark = bookmark;
  }

  return results;
};

export const progressForCourse = (params: {
  course: CourseData;
  completedSecs: CompletedSecData[];
}) => {
  // Results to be returned
  const results = { completed: false, progress: 0 };

  // Get all Course Sections completed by User
  const UserSecIds = params.completedSecs.map((i) => i.SecId);

  // Get all Section Ids for this course
  let SecIds: number[] = [];
  if (params.course.SecIds && params.course.SecIds.length) {
    SecIds = params.course.SecIds;
  } else if (params.course.Sections && params.course.Sections.length) {
    SecIds = params.course.Sections.map((i) => i.SecId);
  }

  if (SecIds.length) {
    // The ID of each Section that has been Completed by the User
    const completedSecIds: number[] = [];

    SecIds.forEach((SecId) => {
      if (UserSecIds.includes(SecId)) {
        // Add this Section ID as Completed
        completedSecIds.push(SecId);
      }
    });

    const hasCompleted = completedSecIds.length === SecIds.length;

    if (hasCompleted) {
      results.completed = true;
      results.progress = 100;
    } else {
      results.completed = false;
      results.progress = Math.round(
        (completedSecIds.length / SecIds.length) * 100
      );
    }
  }

  return results;
};

export const progressForLevel = (params: {
  courses: CourseData[];
  Level: CourseData['Level'];
  completedSecs: CompletedSecData[];
}) => {
  // Results to be returned
  const results = { completed: false, progress: 0 };

  // Get all courses for Level
  const courses = params.courses.filter((i) => i.Level === params.Level);

  if (courses.length) {
    const completedCourses: CourseData[] = [];

    // Track current progress
    let progressCurrent = 0;

    courses.forEach((course) => {
      const courseProgress = progressForCourse({
        course,
        completedSecs: params.completedSecs,
      });
      if (courseProgress.completed) {
        // Add this course to completed courses
        completedCourses.push(course);
      }

      // Update the current progress used to calculate overall progress
      progressCurrent = progressCurrent + courseProgress.progress;
    });

    // Calculate overall progress
    const progressMax = courses.length * 100;
    const progressOverall = Math.round((progressCurrent / progressMax) * 100);

    const hasCompleted = completedCourses.length === courses.length;

    if (hasCompleted) {
      results.completed = true;
      results.progress = 100;
    } else {
      results.completed = false;
      results.progress = progressOverall;
    }
  }

  return results;
};
