import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';
import { AnimateIn } from '../../components/animate-in';
import { DisplayMarkup } from '../../components/display-markup';
import { RouteContainer } from '../../components/route-container';
import { RouteHeader } from '../../components/route-header';
import { GlossaryTermData } from '../../types';
import { captureError } from '../../utils/capture-error';

export function Glossary() {
  // Query - Glossary
  const path = '/glossary';
  const query = useQuery({
    queryKey: [path],
    queryFn: async () => {
      const response: {
        data: GlossaryTermData[];
      } = await API.post('UniversityAPI', path, {});

      if (response.data.length) {
        return response.data;
      }

      return [];
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const lettersAll =
    query.data?.map((i) => i.Title.charAt(0).toUpperCase()) || [];
  const letters = uniq(lettersAll).sort();

  return (
    <RouteContainer
      loading={query.isLoading}
      hasError={query.isError}
      routeTitle="Glossary"
      headerContent={
        <Box>
          <RouteHeader mainText="Glossary" />
          {letters.length ? (
            <AnimateIn notransform="true">
              <Box sx={{ backgroundColor: '#ffffff44' }}>
                <Container>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      paddingTop: 0.5,
                      paddingBottom: 0.5,
                    }}
                  >
                    {letters.map((letter) => {
                      return (
                        <Box
                          key={letter}
                          sx={{
                            a: { color: 'aqua', textDecorationLine: 'none' },
                            fontFamily: 'Bebas Neue',
                            flex: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: 24,
                          }}
                        >
                          <a href={`#${letter}`}>{letter}</a>
                        </Box>
                      );
                    })}
                  </Box>
                </Container>
              </Box>
            </AnimateIn>
          ) : null}
        </Box>
      }
    >
      <Container sx={{ paddingBottom: 4 }}>
        {letters.map((letter) => {
          const terms =
            query.data?.filter(
              (i) => i.Title.charAt(0).toUpperCase() === letter,
            ) || [];
          const orderedTerms = orderBy(terms, [(i) => i.Title.toLowerCase()]);

          return (
            <Paper
              key={letter}
              id={letter}
              sx={{ marginTop: 2, overflow: 'hidden' }}
            >
              <Box
                sx={{
                  padding: 2,
                  backgroundColor: '#606468',
                  fontFamily: 'Bebas Neue',
                  fontSize: 48,
                }}
              >
                {letter}
              </Box>

              {orderedTerms.map((term) => {
                const id = term.Title.toLowerCase()
                  .replaceAll(/[^a-zA-Z0-9\s]/g, '-')
                  .replace(/\s/g, '-');

                return (
                  <Box
                    key={term.TermId}
                    id={id}
                    sx={{
                      padding: 2,
                      display: 'flex',
                      borderTop: '1px solid #ffffff22',
                    }}
                  >
                    <Box sx={{ flex: 1, fontWeight: 'bold' }}>{term.Title}</Box>

                    <Box sx={{ flex: 3, marginLeft: 1, marginRight: 1 }}>
                      <DisplayMarkup
                        sx={{ a: { color: 'aqua' } }}
                        markup={term.Description}
                      />
                    </Box>
                  </Box>
                );
              })}
            </Paper>
          );
        })}
      </Container>
    </RouteContainer>
  );
}
