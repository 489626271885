import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { ContentTile } from '../../components/content-tile';
import { RouteContainer } from '../../components/route-container';
import { HomeVideo } from './home-video';

export function Home() {
  const linksData = [
    {
      text: 'Get started quickly',
      to: '/courses/getting-started-quickly',
      imageUrl:
        'https://a7d9021b860b18c4222a-d761a6fa6590560fc9f9e4d318d2556e.ssl.cf1.rackcdn.com/university/naau-get-started-63a36e721acdc.jpg',
    },
    {
      text: 'All courses',
      to: '/courses',
      imageUrl:
        'https://a7d9021b860b18c4222a-d761a6fa6590560fc9f9e4d318d2556e.ssl.cf1.rackcdn.com/university/naau-courses-63a36e890fd25.jpg',
    },
    {
      text: 'Featured training',
      to: '/featured',
      imageUrl:
        'https://a7d9021b860b18c4222a-d761a6fa6590560fc9f9e4d318d2556e.ssl.cf1.rackcdn.com/university/naau-featured-63a36e9991ba3.jpg',
    },
  ];

  return (
    <RouteContainer routeTitle="Home" notransform="true">
      <div>
        <Paper elevation={0} sx={{ borderRadius: 0 }}>
          <Grid container spacing={0} alignItems="center">
            <Grid item xs={12} md={6}>
              <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                <Box sx={{ position: 'relative' }}>
                  <img
                    alt="Gina Hawks"
                    src="https://a7d9021b860b18c4222a-d761a6fa6590560fc9f9e4d318d2556e.ssl.cf1.rackcdn.com/university/NAAU-home-thumbnail.jpg"
                    style={{ height: '100%', width: '100%', maxWidth: 960 }}
                  />
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      height: '100%',
                      width: '100%',
                      maxWidth: 960,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <HomeVideo />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  height: '100%',
                  width: '100%',
                  maxWidth: 960,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: {
                    xs: 4,
                    md: 0,
                  },
                }}
              >
                <Box sx={{ maxWidth: 360 }}>
                  <Box
                    sx={{
                      fontFamily: 'Bebas Neue',
                      fontSize: 36,
                      color: '#000',
                    }}
                  >
                    Meet the expert
                  </Box>
                  <Box
                    sx={{
                      fontFamily: 'Bebas Neue',
                      fontSize: 72,
                      lineHeight: 1,
                    }}
                  >
                    Gina Hawks
                  </Box>
                  <Box
                    sx={{
                      fontSize: {
                        sm: 16,
                        md: 14,
                        lg: 16,
                        xl: 18,
                      },
                    }}
                  >
                    Gina Hawks has spent more than 25 years in the life
                    insurance industry. For the last 12 years she has worked
                    with The Alliance. NAA University has grown into what it is
                    today because of her efforts and training. She travels the
                    country hosting training sessions for members of The
                    Alliance. Gina and her husband, Phil reside in the Tampa,
                    Florida area.
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>

        <Container>
          <Grid container spacing={4} sx={{ paddingTop: 4, paddingBottom: 4 }}>
            {linksData.map((item) => {
              return (
                <Grid key={item.text} item xs={12} sm={6} md={4}>
                  <ContentTile
                    text={item.text}
                    to={item.to}
                    imageUrl={item.imageUrl}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </div>
    </RouteContainer>
  );
}
