import CloseIcon from '@mui/icons-material/Close';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import React from 'react';
import { DisplayMarkup } from '../../components/display-markup';
import { VideoFrame } from '../../components/video-frame';
import { CompletedSecData, CourseSectionData } from '../../types';

export function CourseSection(props: {
  item: CourseSectionData;
  completedSecs: CompletedSecData[];
  canView: boolean;
  isAuthenticated: boolean;
  updating: number | null;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
}) {
  // Props
  const { item, completedSecs, canView, isAuthenticated, updating } = props;
  // State
  const [viewVideo, setViewVideo] = React.useState(false);

  const CompletedSecIds = completedSecs.map((i) => i.SecId);
  const isCompleted = CompletedSecIds.includes(item.SecId);

  const hasVideo: boolean = item.VideoURL !== null;

  let completeButton = <div />;
  if (hasVideo && !viewVideo && !isCompleted) {
    completeButton = <div />;
  } else if (isAuthenticated || isCompleted) {
    completeButton = (
      <Box
        sx={{
          marginTop: 1,
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <FormControlLabel
          disabled={updating === item.SecId}
          control={
            <Checkbox
              color="success"
              checked={isCompleted}
              onChange={props.onChange}
            />
          }
          label="Mark as completed"
        />
      </Box>
    );
  }

  return (
    <Paper sx={{ marginBottom: 2 }}>
      <Box sx={{ padding: 2 }}>
        <Box
          sx={{
            fontFamily: 'Bebas Neue',
            fontSize: 32,
            color: '#ffffff99',
          }}
        >
          {item.Title}
        </Box>

        {canView ? (
          <Box sx={{ marginTop: 1 }}>
            {item.Description ? (
              <DisplayMarkup
                sx={{ a: { color: 'aqua' } }}
                markup={item.Description}
              />
            ) : null}

            {viewVideo ? (
              <Box>
                <Box sx={{ textAlign: 'right', marginBottom: 1 }}>
                  <Button
                    color="secondary"
                    variant="outlined"
                    startIcon={<CloseIcon />}
                    onClick={() => setViewVideo(false)}
                  >
                    Close player
                  </Button>
                </Box>

                {item.VideoURL ? (
                  <VideoFrame
                    src={item.VideoURL}
                    title={item.Title || 'Alliance University Video'}
                  />
                ) : null}
              </Box>
            ) : hasVideo ? (
              <Box>
                <Button
                  fullWidth
                  size="large"
                  color="secondary"
                  variant="outlined"
                  startIcon={<OndemandVideoIcon color="info" />}
                  onClick={() => setViewVideo(true)}
                >
                  {isCompleted
                    ? 'Watch video'
                    : 'Watch video and complete section'}
                </Button>
              </Box>
            ) : null}

            {completeButton}
          </Box>
        ) : null}
      </Box>
    </Paper>
  );
}
