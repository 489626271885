import React from 'react';
import { NavBar } from './nav-bar';
import { NavDrawer } from './nav-drawer';

export function Navigation() {
  return (
    <React.Fragment>
      <NavBar />

      <NavDrawer />
    </React.Fragment>
  );
}
