import Chip from '@mui/material/Chip';
import React from 'react';
import { Link } from 'react-router-dom';
import { VideoCategoryData, VideoData } from '../types';

export function VideoCategoryChip(props: {
  video: VideoData;
  categories: VideoCategoryData[];
  size?: 'small' | 'medium' | undefined;
  isLink?: boolean;
}) {
  // Props
  const { video, categories } = props;

  // If the course has a Category
  if (video.CategoryId) {
    const category = categories.find((i) => i.CategoryId === video.CategoryId);
    let borderColor: React.CSSProperties['borderColor'] = 'inherit';
    if (category) {
      borderColor = category.Color;
    }

    let to = '';
    if (category && category.Title) {
      const { Title } = category;
      // React router dom uses plus signs for spaces in the URL
      const encodedURI = encodeURIComponent(Title).replace(/%20/g, '+');
      to = `/videos?category=${encodedURI}`;
    }

    return (
      <Chip
        label={category?.Title}
        component={props.isLink ? Link : 'div'}
        to={props.isLink ? to : undefined}
        target="_blank"
        rel="noopener noreferrer"
        size={props.size}
        variant="outlined"
        sx={{
          borderColor,
          borderWidth: 1,
          backgroundColor: '#00000077',
          fontWeight: 'bold',
          cursor: props.isLink ? 'pointer' : 'inherit',
        }}
      />
    );
  } else {
    return null;
  }
}
