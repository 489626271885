import { Auth } from 'aws-amplify';
import { storeRedirectPath } from './redirect';

/**
 * Redirect the user to AWS sign in page and store the current path they are on
 * so they can be redirected to it after signing in
 */
export async function signIn(params: { window: Window }) {
  if (params.window?.location?.pathname) {
    const { pathname } = params.window.location;
    // If the user was on a route other than the main route
    if (pathname !== '/') {
      // Add the route to storage for use after logging in
      await storeRedirectPath({ pathname });
    }
  }

  // Redirect the user to the AWS Auth page
  await Auth.federatedSignIn();
}
