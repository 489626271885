import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useQuery } from '@tanstack/react-query';
import { API } from 'aws-amplify';
import orderBy from 'lodash/orderBy';
import React from 'react';
import { Link } from 'react-router-dom';
import { RouteContainer } from '../../components/route-container';
import { RouteHeader } from '../../components/route-header';
import { SignInBanner } from '../../components/sign-in-banner';
import { AuthContext } from '../../contexts/auth-context';
import { ResourceCategoryData, ResourceData } from '../../types';
import { captureError } from '../../utils/capture-error';
import { ResourceTile } from './resource-tile';

const gridsData = [
  {
    title: 'Final Expense Underwriting Grid',
    to: '/resources/grids/final-expense',
    imageUrl:
      'https://a7d9021b860b18c4222a-d761a6fa6590560fc9f9e4d318d2556e.ssl.cf1.rackcdn.com/university/naau-final-expense-63a36bbc9a1d3.jpg',
  },
  {
    title: 'Mortgage Term Underwriting Grid',
    to: '/resources/grids/mortgage-term',
    imageUrl:
      'https://a7d9021b860b18c4222a-d761a6fa6590560fc9f9e4d318d2556e.ssl.cf1.rackcdn.com/university/naau-mortgage-term-63a36bdb937bc.jpg',
  },
  {
    title: 'Critical Illness Underwriting Grid',
    to: '/resources/grids/critical-illness',
    imageUrl:
      'https://a7d9021b860b18c4222a-d761a6fa6590560fc9f9e4d318d2556e.ssl.cf1.rackcdn.com/university/naau-critical-illness-63a36befcce89.jpg',
  },
  {
    title: 'Hospital Indemnity Underwriting Grid',
    to: '/resources/grids/hospital-indemnity',
    imageUrl:
      'https://a7d9021b860b18c4222a-d761a6fa6590560fc9f9e4d318d2556e.ssl.cf1.rackcdn.com/university/naau-hospital-indemnity-63a36c0db894a.jpg',
  },
  {
    title: 'Product Pocket Guide',
    to: '/resources/product-guide',
    imageUrl:
      'https://a7d9021b860b18c4222a-d761a6fa6590560fc9f9e4d318d2556e.ssl.cf1.rackcdn.com/university/naau-product-guide-63a36c251bd59.jpg',
  },
  {
    title: 'Glossary',
    to: '/resources/glossary',
    imageUrl:
      'https://a7d9021b860b18c4222a-d761a6fa6590560fc9f9e4d318d2556e.ssl.cf1.rackcdn.com/university/naau-glossary-63a36c39b3fd9.jpg',
  },
  {
    title: 'Quoting Tools',
    to: '/resources/carrier-tools',
    imageUrl:
      'https://a7d9021b860b18c4222a-d761a6fa6590560fc9f9e4d318d2556e.ssl.cf1.rackcdn.com/university/naau-carrier-tools-63a36c48e9e39.jpg',
  },
];

export function Resources() {
  // Context
  const { state } = React.useContext(AuthContext);

  // Query - Resources
  const path = '/resources';
  const query = useQuery({
    queryKey: [path],
    queryFn: async () => {
      const response: {
        data: ResourceData[];
      } = await API.post('UniversityAPI', path, {});

      if (response.data.length) {
        return response.data;
      }

      return [];
    },
    onError: (error) => captureError({ data: { error } }),
  });

  // Query - Resource Categories
  const pathCategories = '/resources/categories';
  const queryCategories = useQuery({
    queryKey: [pathCategories],
    queryFn: async () => {
      const response: {
        data: ResourceCategoryData[];
      } = await API.post('UniversityAPI', pathCategories, {});

      if (response.data.length) {
        return response.data;
      }

      return [];
    },
    onError: (error) => captureError({ data: { error } }),
  });

  const isLoading = query.isLoading || queryCategories.isLoading;
  const isError = query.isError || queryCategories.isError;

  // Resource Items
  const items = query.data || [];

  // Resource Categories
  const categories = queryCategories.data || [];

  const isAuthenticated = state.user !== undefined;

  return (
    <RouteContainer
      routeTitle="Resources"
      loading={isLoading}
      hasError={isError}
      headerContent={<RouteHeader mainText="Resources" />}
    >
      <div>
        <Container sx={{ paddingBottom: 4, paddingTop: 4 }}>
          {!isAuthenticated ? (
            <Box sx={{ marginBottom: 2 }}>
              <SignInBanner message="Sign in to view all resources" />
            </Box>
          ) : null}

          <Grid container spacing={4}>
            {gridsData.map((grid) => {
              return (
                <Grid key={grid.to} item xs={12} sm={6} md={4} lg={3}>
                  <Paper
                    component={Link}
                    to={grid.to}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      height: 224,
                      backgroundColor: '#212121',
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      textDecorationLine: 'none',
                      transition: 'all 0.2s',
                      border: `4px solid #ffffff22`,
                      borderStyle: 'inset',
                      ':hover': {
                        transform: 'scale(1.05)',
                        border: `4px solid #ffffff77`,
                      },
                    }}
                  >
                    <Box
                      sx={{
                        flex: 1,
                        backgroundColor: '#3D4349',
                        backgroundImage: grid.imageUrl
                          ? `url(${grid.imageUrl})`
                          : 'none',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        borderRadius: '2px 2px 0 0',
                      }}
                    />
                    <Box
                      sx={{
                        flex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Box
                        sx={{
                          fontSize: 28,
                          fontFamily: 'Bebas Neue',
                          lineHeight: 1,
                          textAlign: 'center',
                        }}
                      >
                        {grid.title}
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>

          {orderBy(categories, 'OrderIndex').map((category) => {
            let resources: ResourceData[] = [];
            if (isAuthenticated) {
              // Display all Resources for this Category
              resources = items.filter(
                (i) => i.CategoryId === category.CategoryId,
              );
            } else {
              // Display all Resources for this Category that are Public
              resources = items.filter(
                (i) => i.CategoryId === category.CategoryId && i.Public,
              );
            }

            if (resources.length) {
              return (
                <Grid
                  key={category.CategoryId}
                  container
                  spacing={4}
                  sx={{ marginTop: 4 }}
                >
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        fontFamily: 'Bebas Neue',
                        fontSize: 52,
                        lineHeight: 1,
                      }}
                    >
                      {category.Title}
                    </Box>
                  </Grid>

                  {orderBy(resources, 'Title').map((item) => {
                    return (
                      <Grid key={item.ResourceId} item xs={12} sm={6} md={4}>
                        <ResourceTile item={item} />
                      </Grid>
                    );
                  })}
                </Grid>
              );
            } else {
              return null;
            }
          })}

          {!isAuthenticated ? (
            <Box sx={{ marginTop: 2 }}>
              <SignInBanner message="Sign in to view all resources" />
            </Box>
          ) : null}
        </Container>
      </div>
    </RouteContainer>
  );
}
