import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useWindowWidth } from '@react-hook/window-size';
import { useQueryClient } from '@tanstack/react-query';
import { Auth } from 'aws-amplify';
import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as AllianceLogo } from '../../assets/alliance-university.svg';
import { AppContext } from '../../contexts/app-context';
import { AuthContext } from '../../contexts/auth-context';
import { usePermissions } from '../../hooks/use-permissions';
import { signIn } from '../../utils/authenticate';
import { captureError } from '../../utils/capture-error';
import { navData } from './nav-data';

export function NavBar() {
  // Context
  const appContext = React.useContext(AppContext);
  const { state } = React.useContext(AuthContext);
  // Hooks
  const width = useWindowWidth();
  const { checkPermissions } = usePermissions();

  const queryClient = useQueryClient();
  const handleSignOut = async () => {
    // Remove any cached data
    queryClient.removeQueries();
    // Sign out current user from Cognito
    await Auth.signOut();
    // After being signed out from Cognito
    // Hub.listen > 'auth' > 'signOut' will remove user  data from Context
  };

  const handleSignIn = async () => {
    try {
      await signIn({ window });
    } catch (error) {
      captureError({ data: { error } });
    }
  };

  const signInButton = (
    <ButtonBase
      focusRipple
      sx={{
        color: '#fff',
        border: '1px solid #ffffff44',
        minHeight: 50,
        borderRadius: 3,
        paddingLeft: 1,
        paddingRight: 1,
      }}
      onClick={handleSignIn}
    >
      <Typography style={{ fontSize: 18 }}>Sign in</Typography>
    </ButtonBase>
  );

  const isDesktop = width > 1024;

  // This empty toolbar is used to offset content
  // Otherwise content would be hidden behind the main AppBar
  const offsetToolbar = <Toolbar />;

  if (isDesktop) {
    return (
      <React.Fragment>
        <AppBar elevation={0} sx={{ backgroundColor: '#CE0E2D' }}>
          <Toolbar>
            <div style={{ flex: 1 }}>
              <Link
                to="/"
                aria-label="home"
                style={{ display: 'inline-block', textDecorationLine: 'none' }}
              >
                <AllianceLogo style={{ width: 110, height: 42 }} />
              </Link>
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {navData.map((item) => {
                let canView = true;
                if (item.authenticated) {
                  if (item.permissions) {
                    canView = checkPermissions(item.permissions);
                  } else {
                    canView = state.user !== undefined;
                  }
                }

                if (canView) {
                  return (
                    <Button
                      key={item.to}
                      component={Link}
                      to={item.to}
                      size="large"
                      style={{
                        color: '#fff',
                        margin: 5,
                        fontFamily: 'Bebas Neue',
                        fontSize: 22,
                        lineHeight: 1,
                        letterSpacing: 1,
                      }}
                    >
                      {item.title}
                    </Button>
                  );
                } else {
                  return null;
                }
              })}
            </div>

            <div
              style={{
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              {state.user !== undefined ? (
                <ButtonBase
                  focusRipple
                  sx={{
                    color: '#fff',
                    border: '1px solid #ffffff44',
                    minHeight: 50,
                    borderRadius: 3,
                    paddingLeft: 1,
                    paddingRight: 1,
                  }}
                  onClick={handleSignOut}
                >
                  <Typography style={{ fontSize: 18 }}>
                    Logout {state.user.getUsername()}
                  </Typography>
                </ButtonBase>
              ) : (
                signInButton
              )}
            </div>
          </Toolbar>
        </AppBar>

        {offsetToolbar}
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <AppBar elevation={0} sx={{ backgroundColor: '#CE0E2D' }}>
          <Toolbar>
            <Link
              to="/"
              aria-label="home"
              style={{ textDecorationLine: 'none' }}
            >
              <AllianceLogo style={{ width: 110, height: 42 }} />
            </Link>

            <div style={{ flex: 1 }} />

            {state.user === undefined ? (
              <Box sx={{ marginRight: 3 }}>{signInButton}</Box>
            ) : null}

            <IconButton
              size="large"
              edge="start"
              color="primary"
              aria-label="menu"
              style={{ color: '#fff', border: '1px solid #ffffff44' }}
              onClick={() => appContext.dispatch({ type: 'TOGGLE_DRAWER' })}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        {offsetToolbar}
      </React.Fragment>
    );
  }
}
