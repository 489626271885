export enum AWS_GROUP {
  Accounting = 'Accounting',
  Commissions = 'Commissions',
  GlobalAdmin = 'GlobalAdmin',
  ImpersonateAll = 'ImpersonateAll',
  Marketing = 'Marketing',
}

export interface VideoSavedData {
  SavedId: number;
  VideoId: VideoData['VideoId'];
  UserId: string | null;
  CreatedDate: string | null;
}

export interface VideoCategoryData {
  CategoryId: number;
  Title: string;
  Color: string;
}

export interface VideoProductData {
  ProductId: number;
  Title: string;
}

export interface VideoCarrierData {
  CarrierId: number;
  Title: string;
}

export interface VideoAssetData {
  AssetId: number;
  VideoId: VideoData['VideoId'];
  Title: string | null;
  AssetFile: string | null;
  Source: string | null;
  CreatedDate: string | null;
}

export interface VideoSectionData {
  SectionId: number;
  VideoId: VideoData['VideoId'];
  OrderIndex: number | null;
  Title: string | null;
  Description: string | null;
}

export interface VideoData {
  VideoId: number;
  VideoSlug: string | null;
  Title: string | null;
  Description: string | null;
  EmbedUrl: string | null;
  ThumbnailFile: string | null;
  ThumbnailUrl: string | null;
  UploadDate: string | null;
  Duration: number | null;
  CategoryId: VideoCategoryData['CategoryId'] | null;
  ProductId: VideoProductData['ProductId'] | null;
  CarrierId: VideoCarrierData['CarrierId'] | null;
  Assets: VideoAssetData[];
  Sections: VideoSectionData[];
}

export interface ResponseYouTube {
  title: string | null;
  author_name: string | null;
  author_url: string | null;
  type: string | null;
  height: number | null;
  width: number | null;
  version: string | null;
  provider_name: string | null;
  provider_url: string | null;
  thumbnail_height: number | null;
  thumbnail_width: number | null;
  thumbnail_url: string | null;
  html: string | null;
}

export interface ResponseVimeo {
  id: number | null;
  title: string | null;
  description: string | null;
  url: string | null;
  upload_date: string | null;
  thumbnail_small: string | null;
  thumbnail_medium: string | null;
  thumbnail_large: string | null;
  user_id: number | null;
  user_name: string | null;
  user_url: string | null;
  user_portrait_small: string | null;
  user_portrait_medium: string | null;
  user_portrait_large: string | null;
  user_portrait_huge: string | null;
  stats_number_of_likes: number | null;
  stats_number_of_plays: number | null;
  stats_number_of_comments: number | null;
  duration: number | null;
  width: number | null;
  height: number | null;
  tags: string | null;
  embed_privacy: string | null;
}

export interface GridTypeData {
  TypeId: number;
  Title: string;
}

export interface GridConditionData {
  ConditionId: number;
  Title: string;
}

export interface GridProductData {
  ProductId: number;
  TypeId: GridTypeData['TypeId'];
  Title: string;
}

export interface GridStatusData {
  StatusId: number;
  ConditionId: GridConditionData['ConditionId'];
  ProductId: GridProductData['ProductId'];
  TypeId: GridTypeData['TypeId'];
  Status: string | null;
  Result: string | null;
}

export interface ResourceCategoryData {
  CategoryId: number;
  OrderIndex: number;
  Title: string;
}

export interface ResourceData {
  ResourceId: number;
  Title: string | null;
  CategoryId: ResourceCategoryData['CategoryId'];
  Description: string | null;
  Source: string | null;
  URL: string | null;
  Image: string | null;
  Public: boolean | null;
}

export interface GlossaryTermData {
  TermId: number;
  Title: string;
  Description: string;
}

export interface QuotingFileData {
  FileId: number;
  OrderIndex: number;
  ToolId: QuotingToolData['ToolId'];
  FileName: string | null;
  QuotingFile: string | null;
  Source: string | null;
}

export interface QuotingToolData {
  ToolId: number;
  OrderIndex: number;
  Color: string | null;
  Title: string | null;
  Description: string | null;
  QuotingFiles: QuotingFileData[];
}

export interface PGInsuranceData {
  TypeId: number;
  OrderIndex: number;
  Title: string;
  Products: PGProductData[];
}

export interface PGProductData {
  ProductId: number;
  OrderIndex: number;
  TypeId: PGInsuranceData['TypeId'];
  Title: string;
  Description: string;
}

export interface BookmarkData {
  BookmarkId: number;
  CourseId: CourseData['CourseId'];
  UserId: string;
}

export interface CompletedSecData {
  CompletedId: number;
  SecId: CourseSectionData['SecId'];
  UserId: string;
}

export interface CourseCategoryData {
  CategoryId: number;
  Title: string;
  Color: string;
}

export interface CourseProductData {
  ProductId: number;
  Title: string;
}

export interface CourseCarrierData {
  CarrierId: number;
  Title: string;
}

export interface CourseSectionData {
  SecId: number;
  CourseId: CourseData['CourseId'];
  OrderIndex: number | null;
  Title: string | null;
  Description: string | null;
  VideoURL: string | null;
  CreatedDate: string | null;
}

export interface CourseAssetData {
  AssetId: number;
  CourseId: CourseData['CourseId'];
  Title: string | null;
  AssetFile: string | null;
  Source: string | null;
  CreatedDate: string | null;
}

export interface CourseData {
  CourseId: number;
  CourseSlug: string | null;
  CourseNo: number | null;
  Title: string | null;
  CategoryId: CourseCategoryData['CategoryId'] | null;
  ProductId: CourseProductData['ProductId'] | null;
  CarrierId: CourseCarrierData['CarrierId'] | null;
  Required: boolean | null;
  Description: string | null;
  Image: string | null;
  Level: string | null;
  Published: boolean | null;
  Certificate: boolean | null;
  FeatCarousel: 0 | 1 | null;
  FeatAdded: 0 | 1 | null;
  FeatUpdated: 0 | 1 | null;
  UpdatedAt: string | null;
  CreatedDate: string | null;
  SecIds: number[] | null;
  Assets: CourseAssetData[];
  Sections: CourseSectionData[];
}
