import React from 'react';
import { captureError } from '../../utils/capture-error';
import { RouteError } from './route-error';

export class RouteErrorBoundary extends React.Component<{
  children: React.ReactNode;
}> {
  state = { hasError: false };

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    captureError({ data: { error, errorInfo } });
  }

  render() {
    if (this.state.hasError) {
      return <RouteError />;
    } else {
      return this.props.children;
    }
  }
}
